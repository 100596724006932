import { createStore } from 'vuex'
import {Store} from "@/models/Interfaces";
import {UserModel} from "@/models/UserModel";
import router from "@/router";
import {ToastsService} from "@/components/ToastService/ToastsService";

export default createStore<Store>({
	state: {
		user: new UserModel(),
		toasts: new ToastsService(),
	},
	getters: {
	},
	mutations: {
	},
	actions: {
		logout({commit}) {
			commit('setUser', new UserModel())
		},
		async getUserData ({ state }) {
			await state.user.me()
				.catch((error:any) => {
					if (!error.response && error.response.status === 401) {
						localStorage.removeItem('token')
						router.push({
							name: 'auth',
						})
					}
				})
		},
	},
	modules: {
	}
})
