
import {defineComponent, PropType} from "vue";
import {PaginationMeta} from "@/models/Interfaces";

export default defineComponent({
    emits: ['input'],
    props: {
        meta: {
            type: Object as PropType<PaginationMeta>,
            required: true
        },
        pageRange: {
            type: Number,
            default: 3
        },
        marginPages: {
            type: Number,
            default: 1
        },
    },
    data() {
        return {
            innerValue: 1
        }
    },
    computed: {
        selected: {
            get: function() {
                return this.meta.current_page || this.innerValue
            },
            set: function(newValue: number) {
                this.innerValue = newValue
            }
        },
        pages: function () {
            let items = {} as {[name:string|number]: any}
            if (this.meta.total_pages <= this.pageRange) {
                for (let index = 0; index < this.meta.total_pages; index++) {
                    let page = {
                        index: index,
                        content: index + 1,
                        selected: index === (this.selected - 1)
                    }
                    items[index] = page
                }
            }
            else
            {
                const halfPageRange = Math.floor(this.pageRange / 2)
                let setPageItem = (index: number) => {
                    let page = {
                        index: index,
                        content: index + 1,
                        selected: index === (this.selected - 1)
                    }
                    items[index] = page
                }
                let setBreakView = (index: number) => {
                    let breakView = {
                        disabled: true,
                        breakView: true
                    }
                    items[index] = breakView
                }
                // 1st - loop thru low end of margin pages
                for (let i = 0; i < this.marginPages; i++) {
                    setPageItem(i);
                }
                // 2nd - loop thru selected range
                let selectedRangeLow = 0;
                if (this.selected - halfPageRange > 0) {
                    selectedRangeLow = this.selected - 1 - halfPageRange;
                }
                let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;
                if (selectedRangeHigh >= this.meta.total_pages) {
                    selectedRangeHigh = this.meta.total_pages - 1;
                    selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
                }
                for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.meta.total_pages - 1; i++) {
                    setPageItem(i);
                }
                // Check if there is breakView in the left of selected range
                if (selectedRangeLow > this.marginPages) {
                    setBreakView(selectedRangeLow - 1)
                }
                // Check if there is breakView in the right of selected range
                if (selectedRangeHigh + 1 < this.meta.total_pages - this.marginPages) {
                    setBreakView(selectedRangeHigh + 1)
                }
                // 3rd - loop thru high end of margin pages
                for (let i = this.meta.total_pages - 1; i >= this.meta.total_pages - this.marginPages; i--) {
                    setPageItem(i);
                }
            }
            return items
        },
        lastPageSelected: function () {
            return (this.selected === this.meta.total_pages) || (this.meta.total_pages === 0)
        }
    },
    methods: {
        handlePageSelected(selected: number) {
            if (this.selected === selected) return
            this.innerValue = selected
            this.$emit('input', selected)
        },
        firstPageSelected() {
            return this.selected === 1
        },
    }
})
