
import {defineComponent} from 'vue';
import GCard from "@/components/Base/GCard.vue";
import {httpClient} from "@/models/HttpClient";
import {Pagination, SiteTemplate} from "@/models/Interfaces";
import {AxiosResponse} from "axios";
import GPaginate from "@/components/Base/GPaginate.vue";
import CreateTemplatePopup from "@/views/Templates/popups/CreateTemplatePopup.vue";

export default defineComponent({
    name: "TemplatesIndex",
    components: {CreateTemplatePopup, GPaginate, GCard},
    created() {
        this.loadSiteTemplates()
    },
    data() {
        return {
            templates: {
                models: [] as Array<SiteTemplate>,
                meta: {
                    per_page: 10,
                    current_page: 1
                }
            } as Pagination<SiteTemplate>,
            showCreateTemplatePopup: false
        }
    },
    methods: {
        loadSiteTemplates(current_page:number|null = null) {
            if (!current_page) current_page = this.templates.meta.current_page

            httpClient.get(`/api/site-templates`, {
                params: {
                    page: current_page,
                    per_page: this.templates.meta.per_page
                }
            })
                .then((response: AxiosResponse<Pagination<SiteTemplate>>) => {
                    this.templates.models = response.data.models
                    this.templates.meta.current_page = response.data.meta.current_page
                    this.templates.meta.total = response.data.meta.total
                    this.templates.meta.total_pages = response.data.meta.total_pages
                    this.templates.meta.count = response.data.meta.count
                })
        },
        openCreateTemplate() {
            this.showCreateTemplatePopup = true
        },
        closeCreateTemplate() {
            this.showCreateTemplatePopup = false
            this.loadSiteTemplates()
        }
    }
});
