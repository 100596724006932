import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_g_loader = _resolveComponent("g-loader")!
  const _component_g_select = _resolveComponent("g-select")!
  const _component_g_card = _resolveComponent("g-card")!
  const _component_service_modal = _resolveComponent("service-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_g_card, null, _createSlots({
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createTextVNode(" Услуги "),
          _createElementVNode("a", {
            class: "ms-auto btn btn-sm btn-outline-success",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openServiceModal && _ctx.openServiceModal(...args)))
          }, "Создать")
        ])
      ]),
      _: 2
    }, [
      (_ctx.state.loadingServices)
        ? {
            name: "body",
            fn: _withCtx(() => [
              _createVNode(_component_g_loader)
            ]),
            key: "0"
          }
        : {
            name: "body",
            fn: _withCtx(() => [
              _createElementVNode("div", { class: "row" }, [
                _createElementVNode("div", { class: "col-6 col-lg-4" }, [
                  _createVNode(_component_g_select, {
                    "can-clear": "",
                    placeholder: "Группа",
                    modelValue: _ctx.filter.serviceGroupId,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter.serviceGroupId) = $event)),
                    options: _ctx.serviceGroups.map(row => {
                            return {
                                value: row.id,
                                name: row.name
                            }
                        })
                  }, null, 8, ["modelValue", "options"])
                ]),
                _createElementVNode("div", { class: "col-6 col-lg-4" }, [
                  _createVNode(_component_g_select, {
                    placeholder: "Тип питомца",
                    "can-clear": "",
                    modelValue: _ctx.filter.petTypeId,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter.petTypeId) = $event)),
                    options: _ctx.petTypes.map(row => {
                            return {
                                value: row.id,
                                name: row.name
                            }
                        })
                  }, null, 8, ["modelValue", "options"])
                ])
              ]),
              _createElementVNode("table", { class: "table b-table" }, [
                _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", null, "#"),
                    _createElementVNode("th", null, "Название"),
                    _createElementVNode("th", null, "Тип питомца"),
                    _createElementVNode("th", null, "Группа")
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredServices, (service) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: `service_${service.id}`,
                      class: "table-tr-hovered",
                      onClick: ($event: any) => (_ctx.openServiceModal(service))
                    }, [
                      _createElementVNode("td", null, _toDisplayString(service.id), 1),
                      _createElementVNode("td", null, _toDisplayString(service.name), 1),
                      _createElementVNode("td", null, _toDisplayString(service.pet_type.name), 1),
                      _createElementVNode("td", null, _toDisplayString(service.service_group.name), 1)
                    ], 8, ["onClick"]))
                  }), 128))
                ])
              ])
            ]),
            key: "1"
          }
    ]), 1024),
    (_ctx.state.showServiceModal)
      ? (_openBlock(), _createBlock(_component_service_modal, {
          key: 0,
          "service-groups": _ctx.serviceGroups,
          "prop-model": _ctx.service,
          "pet-types": _ctx.petTypes,
          onClose: _ctx.closeServiceModal
        }, null, 8, ["service-groups", "prop-model", "pet-types", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}