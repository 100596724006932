import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import {echo} from "@/Socket";
import { vMaska } from "maska"
import VueCookies from 'vue-cookies'

import moment from 'moment'
import "./sass/app.sass"

const app = createApp(App);

app.config.globalProperties.$moment=moment;

app
	.directive('maska', vMaska)
	.use(store)
	.use(router)
	.use(VueCookies, { expires: '7d'})
	.mount('#app')


// window.Echo = echo
