
import {defineComponent} from "vue";

export default defineComponent({
    emits: ['update:modelValue'],
    props: {
        label: {
            type: String,
            required: false
        },
        modelValue: {
            type: Boolean
        },
        errors: {
            type: Array,
        },
        formSwitch: {
            type: Boolean,
        },
        class: {
            type: String,
            default: "mb-2"
        },
        formText: {
            required: false,
            default: null
        }
    },
    watch: {
        'modelValue': function (value) {
            this.value = value
        },
        'value': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$emit('update:modelValue', newVal)
            }
        }
    },
    data() {
        return {
            value: this.modelValue
        }
    }
})
