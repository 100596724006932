
import {defineComponent} from "vue";
import GCard from "@/components/Base/GCard.vue";
import { Line } from 'vue-chartjs'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement
} from 'chart.js'
import {UserModel} from "@/models/UserModel";
import store from "@/store";
import {DashboardData, Organization, Pagination} from "@/models/Interfaces";
import {httpClient} from "@/models/HttpClient";
import {AxiosResponse} from "axios";
import GLoader from "@/components/Base/GLoader.vue";
import GPaginate from "@/components/Base/GPaginate.vue";
import {tariffTypes} from "@/defData";

ChartJS.register(CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend)

export default defineComponent({
    components: {GLoader, GCard, Line},
    computed: {
        user() : UserModel {
            return store.state.user
        },
        chartData() : any {
            return {
                labels: Object.keys(this.dashboard.new_organization_by_days),
                datasets: [
                    {
                        label: '',
                        backgroundColor: '#1b8754',
                        data: Object.values(this.dashboard.new_organization_by_days),
                    },
                ],
            }
        },
        logsData() : any {
            return {
                labels: Object.keys(this.dashboard.logs_count_by_days),
                datasets: [
                    {
                        label: '',
                        backgroundColor: '#1b8754',
                        data: Object.values(this.dashboard.logs_count_by_days),
                    },
                ],
            }
        },
    },
    created() {
        this.loadDashboard()
        this.loadOrganizations()
    },
    data() {
        return {
            tariffTypes,
            dashboard: {
                new_organization_by_days: {},
                logs_count_by_days: {},
            } as DashboardData,

            chartOptions: {
                responsive: false,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        min: 0,
                        ticks: {
                            stepSize: 1
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    }
                }
            },
            organizations: [] as Organization[],
            state: {
                isOrganizationsLoading: false
            }
        }
    },
    methods: {
        loadDashboard() {
            this.user.loadDashboard()
                .then(response => {
                    this.dashboard = response.data.data
                })
        },
        loadOrganizations() {
            this.state.isOrganizationsLoading = true
            httpClient
                .get(`/api/organizations-with-tickets`, {
                })
                .then((response: AxiosResponse<{data: Organization[]}>) => {
                    this.organizations = response.data.data
                })
                .finally(() => {
                    this.state.isOrganizationsLoading = false
                })
        }
    }
})
