import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_g_loader = _resolveComponent("g-loader")!
  const _component_g_card = _resolveComponent("g-card")!
  const _component_service_group_modal = _resolveComponent("service-group-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_g_card, { class: "mb-3" }, _createSlots({
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createTextVNode(" Группы услуг "),
          _createElementVNode("a", {
            class: "ms-auto btn btn-sm btn-outline-success",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openServiceGroupModal && _ctx.openServiceGroupModal(...args)))
          }, "Создать")
        ])
      ]),
      _: 2
    }, [
      (_ctx.state.loadingServiceGroups)
        ? {
            name: "body",
            fn: _withCtx(() => [
              _createVNode(_component_g_loader)
            ]),
            key: "0"
          }
        : {
            name: "body",
            fn: _withCtx(() => [
              _createElementVNode("table", { class: "table b-table" }, [
                _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", null, "#"),
                    _createElementVNode("th", null, "Название"),
                    _createElementVNode("th", null, "Кол-во услуг")
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceGroups, (row) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: `s_${row.id}`,
                      class: "table-tr-hovered",
                      onClick: ($event: any) => (_ctx.openServiceGroupModal(row))
                    }, [
                      _createElementVNode("td", null, _toDisplayString(row.id), 1),
                      _createElementVNode("td", null, _toDisplayString(row.name), 1),
                      _createElementVNode("td", null, _toDisplayString(row.services_count), 1)
                    ], 8, ["onClick"]))
                  }), 128))
                ])
              ])
            ]),
            key: "1"
          }
    ]), 1024),
    (_ctx.state.showServiceGroupModal)
      ? (_openBlock(), _createBlock(_component_service_group_modal, {
          key: 0,
          "prop-model": _ctx.serviceGroup,
          onClose: _ctx.closeServiceGroupModal
        }, null, 8, ["prop-model", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}