
import {defineComponent} from 'vue';
import {ToastsService} from "@/components/ToastService/ToastsService";
import store from "@/store";

export default defineComponent({
    name: "ToastsService",
    computed: {
        toasts(): ToastsService {
            return store.state.toasts
        }
    },
    methods: {
        close(id?: number) {
            if (id)
                this.toasts.close(id)
        },
        rejectAutoClose(id?: number) {
            if (id)
                this.toasts.rejectAutoClose(id)
        },
        startAutoClose(id?: number) {
            if (id)
                this.toasts.startAutoClose(id)
        }
    }
});
