import {defineComponent, ref} from 'vue';

export default defineComponent({
	name: "DropDownMenu",
    setup() {
        const dropdown: any = ref(null)

        return {
            dropdown
        }
    },
    props: {
        btnClass: {
            type: String,
            required: false,
            default: 'pointer d-flex align-items-center'
        },
        top: {
            type: Boolean,
            required: false
        },
        left: {
            type: Boolean,
            required: false
        }
    },
    data() {

        return {
            isOpen: false,
        }
    },
    methods: {
        toggleMenu() {
          if (!this.isOpen) {
              this.openMenu()
          } else {
              this.closeMenu()
          }
        },
        openMenu() {
            this.isOpen = true;

            document.addEventListener('click', this.handleClickOut)
        },
        closeMenu() {
            this.isOpen = false

            document.removeEventListener('click', this.handleClickOut)
        },
        handleClickOut(event: any) {
            if (!this.dropdown.contains(event.target)) {
                this.closeMenu()
            }
        }
    },
    unmounted() {
        document.removeEventListener('click', this.handleClickOut)
    }
});
