
import {defineComponent} from 'vue';
import GModal from "@/components/GModal.vue";
import GText from "@/components/Base/GText.vue";
import BtnLoading from "@/components/BtnLoading.vue";
import {httpClient, LaravelErrorResponse} from "@/models/HttpClient";
import store from "@/store";
import {ToastTypes} from "@/components/ToastService/ToastsService";
import {AxiosError} from "axios";

export default defineComponent({
    name: "OrganizationCreateInvoiceWithEnrollModal",
    components: {BtnLoading, GText, GModal},
    props: {
        organization_id: {
            required: true
        },
        monthPrice: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            transaction: {
                month: 0,
            },
            state: {
                creating: false
            }
        }
    },
    methods: {
        create() {
            this.state.creating = true
            httpClient
                .post(`/api/app/v1/organizations/${this.organization_id}/create-invoice-with-enroll`, this.transaction)
                .then(() => {
                    this.close()
                })
                .catch((error: AxiosError<LaravelErrorResponse>) => {
                    store.state.toasts.addToast({
                        title: error?.response?.data.message ?? 'Ошибка!',
                        type: ToastTypes.error
                    })
                })
                .finally(() => {
                    this.state.creating = false
                })
        },
        close() {
            this.$emit('close')
        }
    }
});
