
import {defineComponent} from 'vue';
import {ISalonReview, Pagination, PaginationMeta} from "@/models/Interfaces";
import {httpClient} from "@/models/HttpClient";
import {AxiosResponse} from "axios";
import GCard from "@/components/Base/GCard.vue";
import GPaginate from "@/components/Base/GPaginate.vue";
import store from "@/store";
import {ToastTypes} from "@/components/ToastService/ToastsService";

export default defineComponent({
    name: "ReviewsIndex",
    components: {GPaginate, GCard},
    data() {
        return {
            meta: {
                current_page: 1,
                per_page: 10
            } as PaginationMeta,
            models: [] as Array<ISalonReview>,
        }
    },
    methods: {
        loadData() {
            httpClient
                .get(`/api/v1/salon-reviews`, {
                    params: {
                        page: this.meta.current_page,
                        per_page: this.meta.per_page,
                    }
                })
                .then((response: AxiosResponse<Pagination<ISalonReview>>) => {
                    this.meta.total_pages = response.data.meta.total_pages
                    this.meta.count = response.data.meta.count

                    this.models = response.data.models
                })
        },
        setPage(page: number) {
            this.meta.current_page = page
            this.loadData()
        },
        setVisibility(row: ISalonReview) {
            httpClient
                .post(`/api/v1/salons/${row.salon_id}/reviews/${row.id}`, {
                    action: 'set-visibility',
                    visibility: !row.visibility
                })
                .then(() => {
                    row.visibility = !row.visibility
                    store.state.toasts.addToast({
                        title: 'Сохранили!',
                        type: ToastTypes.success
                    })
                })
        }
    },
    created() {
        this.loadData()
    }
});
