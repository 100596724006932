
import {defineComponent, PropType} from "vue";
import {GFile} from "@/models/Interfaces";

export default defineComponent({
    emits: ['update:modelValue'],
    props: {
        label: {
            type: String,
            required: false
        },
        modelValue: {
            type: Object as PropType<GFile>,
            required: true
        },
        errors: {
            type: Array,
        },
        type: {
            type: String,
            default: 'file'
        },
        required: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        'modelValue': function (value) {
            this.value = value
        },
        'value': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$emit('update:modelValue', newVal)
            }
        }
    },
    data() {
        return {
            value: this.modelValue as GFile
        }
    },
    methods: {
        uploadFile() {
            let files = this.$refs.file as HTMLInputElement
            if (files.files && files.files[0])
                this.value.new_file = files.files[0]
                //this.$emit('update:modelValue', files.files[0])
        }
    }
})
