
import {defineComponent} from "vue";
import {httpClient, LaravelErrorResponse} from "@/models/HttpClient";
import {Log, Organization} from "@/models/Interfaces";
import {AxiosError, AxiosResponse} from "axios";
import GCard from "@/components/Base/GCard.vue";
import GLoader from "@/components/Base/GLoader.vue";
import GText from "@/components/Base/GText.vue";
import {VersionsManager} from "@/models/VersionsManager";
import FullPageLoader from "@/components/FullPageLoader.vue";
import SalonsIndex from "@/views/Salons/SalonsIndex.vue";
import BtnLoading from "@/components/BtnLoading.vue";
import GSelect from "@/components/Base/GSelect.vue";
import {tariffTypes} from "@/defData";
import store from "@/store";
import {ToastTypes} from "@/components/ToastService/ToastsService";
import GChecks from "@/components/Base/GChecks.vue";
import OrganizationCreateInvoiceWithOutEnrollModal
    from "@/views/Organizations/OrganizationCreateInvoiceWithOutEnrollModal.vue";
import OrganizationCreateInvoiceWithEnrollModal
    from "@/views/Organizations/OrganizationCreateInvoiceWithEnrollModal.vue";

export default defineComponent({
    components: {
        OrganizationCreateInvoiceWithEnrollModal,
        OrganizationCreateInvoiceWithOutEnrollModal,
        GChecks, GSelect, BtnLoading, SalonsIndex, FullPageLoader, GText, GLoader, GCard},
    props: {
        organization_id: {
            type: String
        }
    },
    data() {
        return {
            model: {
                id: 0,
                logs: [] as Array<Log>
            } as Organization,
            types: {
                salon: 'Салон',
                single: 'Частный мастер'
            },
            states: {
                createServe: false,
                proxy_nginx: false,
                project_code: false,
                docker_code: false,
                docker_compose: false,
            },
            tab: 0,
            tabs: [
                { id:0, name: 'Заметки' },
                // { id:1, name: 'S3' },
                { id:3, name: 'Логи' },
                { id:4, name: 'Работники' },
                { id:5, name: 'Салоны' },
                // { id:6, name: 'Ресурсы' },
                { id:7, name: 'Дополнительно' },
                { id:8, name: 'Настройки оплаты' },
                { id:9, name: 'История оплаты' },
                { id:10, name: 'Настройки системы' },
            ],
            versionManager: new VersionsManager(),
            users: {} as v1_system_user_index,
            isLoading: false,
            status: 'проверка',
            state: {
                backup: false,
                autoDeploy: false,
                autoDeployUpdate: false,
                savingTariffData: false,
                savingComment: false,
                systemSettingLoading: false,
                systemSettingSaving: false,
                setPause: false,
                setPlay: false,
                create_invoice: false,
                create_invoice_enroll: false,
                startSaas: false,
            },
            tariffTypes,
            transactions: [] as Array<ITransaction>,
            comment: '',
            showSystemSettings: ['subscription_salons_limit', 'notifications_sms_active', 'notifications_wapico_access_token','notifications_whatsapp_active', 'lk_app_android_link', 'lk_app_ios_link', 'notifications_sms_max_symbols', 'organization_is_updating', 'organization_is_transfer', 'organization_new_url'],
            system_settings: [] as Array<ISystemSettings>,
            otp: '',
        }
    },
    methods: {
        loadOrganization() {
            this.isLoading = true
            httpClient.get(`/api/organizations/${this.organization_id}`)
                .then((response : AxiosResponse<{organization_data: Organization}>) => {
                    this.model = response.data.organization_data
                })
                .then(() => {
                    this.loadUsers()
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        orgManage(action: string) {
            this.isLoading = true
            httpClient
                .post(`/api/organizations/${this.organization_id}`, {
                    _method: 'patch',
                    action: action
                })
                .then(() => {
                    this.loadOrganization()
                })
                .catch(() => {
                    alert('Произошла ошибка');
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        startSaas() {
            this.isLoading = true
            this.state.startSaas = true
            httpClient
                .post(`/api/organizations/${this.organization_id}`, {
                    _method: 'patch',
                    action: 'create-space',
                })
                .then(() => {
                    this.loadOrganization()
                })
                .catch(() => {
                    alert('Произошла ошибка');
                })
                .finally(() => {
                    this.isLoading = false
                    this.state.startSaas = false
                })
        },
        deploy() {
            this.state.autoDeploy = true
            if (confirm(`Заполняем базу?`)) {
                httpClient
                    .post(`/api/app/v1/organizations/${this.organization_id}/auto-deploy/start`, {
                        timeout: 5000000
                    })
                    .then(() => {
                        alert('Готово')
                    })
                    .catch((error: AxiosError<LaravelErrorResponse>) => {
                        let msg = error?.response?.data.message

                        alert(msg ?? 'Ошибка')
                    })
                    .finally(() => {
                        this.state.autoDeploy = false
                    })
            }
        },
        update() {
            if (confirm(`Обновляем ?`)) {
                this.state.autoDeployUpdate = true
                httpClient
                    .post(`/api/app/v1/organizations/${this.organization_id}/auto-deploy/update`, {
                        timeout: 5000000
                    })
                    .then(() => {
                        alert('Готово')
                    })
                    .catch((error: AxiosError<LaravelErrorResponse>) => {
                        let msg = error?.response?.data.message

                        alert(msg ?? 'Ошибка')
                    })
                    .finally(() => {
                        this.state.autoDeployUpdate = false
                    })
            }
        },
        createSuperadmin() {
            const phone = this.model.phone.replaceAll(' ', '')

            if (confirm(`Создаем суперадмина ${phone}`)) {
                httpClient
                    .post(`/api/app/v1/organizations/${this.organization_id}/users`, {
                        phone: phone
                    })
                    .then(() => {
                        alert('Создали')
                    })
                    .catch((error: AxiosError<LaravelErrorResponse>) => {
                        let msg = error?.response?.data.message

                        alert(msg ?? 'Ошибка')
                    })
                    .finally(() => {
                        this.loadUsers()
                    })
            }
        },
        loadUsers() {
            this.isLoading = true
            httpClient
                .get(`/api/app/v1/organizations/${this.organization_id}/users`)
                .then((response: AxiosResponse<{v1_system_user_index: v1_system_user_index}>) => {
                    this.users = response.data.v1_system_user_index
                    this.status = 'Доступен'
                })
                .catch(() => {
                    this.status = 'Не доступен'
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        login(user: v1_system_user) {
            this.isLoading = true
            httpClient
                .post(`/api/app/v1/organizations/${this.organization_id}/users/login`, {
                    phone: user.phone
                })
                .then((response: AxiosResponse<{login_data: login_data}>) => {
                    this.$cookies.remove('crm-token')
                    this.$cookies.set("crm-token", `Bearer ${response.data.login_data.bearer_token}`,'1d', '/', `.cloud-bimassist.ru`);
                    window.open(`https://space${this.model.id}.cloud-bimassist.ru/crm`, 'blank')
                })
                .catch(() => {
                    alert('Что то не так')
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        setDefaultResource() {
            if (confirm('Вернуть?')) {
                this.isLoading = true
                httpClient
                    .post(`/api/organizations/${this.organization_id}`, {
                        _method: 'patch',
                        action: 'set-default-resource',
                    })
                    .then(() => {
                        this.loadOrganization()
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }
        },
        setResource() {
            this.isLoading = true
            httpClient
                .post(`/api/organizations/${this.organization_id}`, {
                    _method: 'patch',
                    action: 'set-resource',
                    resource: this.model.resources
                })
                .catch(() => {
                    alert('Ошибка!')
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        setSaasResource() {
            if (confirm('Применить?')) {
                this.isLoading = true
                httpClient
                    .post(`/api/organizations/${this.organization_id}`, {
                        _method: 'patch',
                        action: 'set-saas-resource',
                        resource: this.model.resources
                    })
                    .catch(() => {
                        alert('Ошибка!')
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }
        },
        createBackUp() {
            ///api/app/v1/organizations/:organization_id/backup/db
            if (confirm('Далем бэкап?')) {
                this.state.backup = true
                httpClient
                    .post(`/api/app/v1/organizations/${this.organization_id}/backup/db`)
                    .then(() => {
                        alert('Сделали!')
                    })
                    .finally(() => {
                        this.state.backup = false
                    })
            }
        },
        setDatePaidUntilDate(e: InputEvent){
            this.model.paid_until_date = (e.target as HTMLInputElement).value
        },
        saveTariffData() {
            this.isLoading = true
            httpClient
                .post(`/api/organizations/${this.organization_id}`, {
                    _method: 'patch',
                    action: 'set-tariff-data',
                    tariff_type: this.model.tariff_type,
                    paid_until_date: this.model.paid_until_date,
                    price: this.model.price
                })
                .catch(() => {
                    alert('Произошла ошибка');
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        loadTransactions() {
            this.isLoading = true

            httpClient
                .get(`/api/app/v1/organizations/${this.organization_id}/transactions`)
                .then((response: AxiosResponse<Array<ITransaction>>) => {
                    this.transactions = response.data
                })
                .catch(() => {
                    alert('Произошла ошибка');
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        loadPayUrl(t: ITransaction) {
            httpClient
                .get(`/api/app/v1/organizations/${this.organization_id}/transactions/${t.id}/get-pay-url`)
                .then((response: AxiosResponse<string>) => {
                    t.pay_url = response.data
                })
        },
        createTransaction() {
            let result = prompt(this.model.tariff_type === 1 ? 'Кол-во месяцев' : 'Кол-во записей' , "0");
            if (result && parseInt(result)) {
                httpClient
                    .post(`/api/app/v1/organizations/${this.organization_id}/transactions`, {
                        from_value: parseInt(result)
                    })
                    .then((result: AxiosResponse<ITransaction>) => {
                        this.transactions.unshift(result.data)
                    })
            }
        },
        sendComment() {
            this.state.savingComment = true
            httpClient
                .post(`/api/organizations/${this.organization_id}`, {
                    _method: 'patch',
                    action: 'add-comment',
                    comment: this.comment
                })
                .then((response : AxiosResponse<{organization_data: Organization}>) => {
                    this.comment = ''
                    this.model.comments = response.data.organization_data.comments
                })
                .catch(() => {
                    alert('Произошла ошибка');
                })
                .finally(() => {
                    this.state.savingComment = false
                })

        },
        loadSystemSettings() {
            this.state.systemSettingLoading = true
            httpClient
                .get(`/api/app/v1/organizations/${this.organization_id}/system-settings`)
                .then((response: AxiosResponse<{ v1_app_system_setting_index: v1_app_system_setting_index }>) => {
                    this.system_settings = response.data.v1_app_system_setting_index.system_settings
                })
                .catch(() => {
                    store.state.toasts.addToast({
                        title: 'Ошибка!',
                        type: ToastTypes.error
                    })
                })
                .finally(() => {
                    this.state.systemSettingLoading = false
                })
            //app/v1/organizations/
        },
        setSystemSettings(key: string) {
            let data = this.system_settings.find(row => row.key === key)
            if (data) {
                data.isSaving = true
                httpClient
                    .post(`/api/app/v1/organizations/${this.organization_id}/system-settings/${key}`, {
                        otp: this.otp.replace(' ', ''),
                        value: data.value
                    })
                    .catch(() => {
                        store.state.toasts.addToast({
                            title: 'Ошибка!',
                            type: ToastTypes.error
                        })
                    })
                    .finally(() => {
                        data!.isSaving = false
                    })
            }
        },
        setPause() {
            this.state.setPause = true
            httpClient
                .post(`/api/organizations/${this.organization_id}`, {
                    action: 'set-pause',
                    _method: 'patch'
                })
                .then((response : AxiosResponse<{organization_data: Organization}>) => {
                    this.model.status = response.data.organization_data.status
                })
                .finally(() => {
                    this.state.setPause = false
                })
        },
        setPlay() {
            this.state.setPlay = true
            httpClient
                .post(`/api/organizations/${this.organization_id}`, {
                    action: 'set-play',
                    _method: 'patch'
                })
                .then((response : AxiosResponse<{organization_data: Organization}>) => {
                    this.model.status = response.data.organization_data.status
                })
                .finally(() => {
                    this.state.setPlay = false
                })
        },
        openCreateInvoiceModal() {
            this.state.create_invoice = true
        },
        closeCreateInvoiceModal() {
            this.state.create_invoice = false
            this.loadTransactions()
        },
        openCreateInvoiceEnrollModal() {
            this.state.create_invoice_enroll = true
        },
        closeCreateInvoiceEnrollModal() {
            this.state.create_invoice_enroll = false
            this.loadTransactions()
        }
    },
    created() {
        this.loadOrganization()
        this.loadTransactions()
    },
    watch: {
        'tab': function (value: number) {
            switch (value) {
                case 10:
                    this.loadSystemSettings()
                    break;
            }
        }
    }
})

export interface v1_system_user_index {
    users: Array<v1_system_user>
}

interface v1_system_user {
    id: number
    phone: string
    firstname: string
    lastname: string
    is_master: boolean
    is_staff: boolean
    is_super_admin: boolean
}

interface login_data {
    bearer_token: string
}

interface ITransaction {
    id: number
    status: number
    tariff_type: number
    from_value: number

    value: number
    comment: string
    pay_url?: string
    enrolled_at: string|null
    created_at: string
}

interface v1_app_system_setting_index {
    system_settings: Array<ISystemSettings>
}

interface ISystemSettings {
    id: number
    key: string
    name: string
    type: "string"|"boolean"|"int"
    value: null|string|number|boolean
    isSaving: boolean
}

