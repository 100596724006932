import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from "@/store";
import AuthIndex from "@/views/Auth/AuthIndex.vue";
import BaseView from "@/views/BaseView.vue";
import DashboardView from "@/views/Dashboard/DashboardView.vue";
import OrganizationsIndex from "@/views/Organizations/OrganizationsIndex.vue";
import OrganizationShow from "@/views/Organizations/OrganizationShow.vue";
import TemplatesIndex from "@/views/Templates/TemplatesIndex.vue";
import TemplateShow from "@/views/Templates/TemplateShow.vue";
import RegistrationRequestsIndex from "@/views/RegistrationRequests/RegistrationRequestsIndex.vue";
import VersionsIndex from "@/views/Versions/VersionsIndex.vue";
import TicketsIndex from "@/views/Tickets/TicketsIndex.vue";
import TicketShow from "@/views/Tickets/TicketShow.vue";
import ProfileIndex from "@/views/Profile/ProfileIndex.vue";
import SalonsIndex from "@/views/Salons/SalonsIndex.vue";
import SalonsMap from "@/views/Salons/SalonsMap.vue";
import OrdersIndex from "@/views/Orders/OrdersIndex.vue";
import OrganizationOrders from "@/views/Organizations/OrganizationOrders.vue";
import SalonRequestsIndex from "@/views/Salons/Requests/SalonRequestsIndex.vue";
import SettingsIndex from "@/views/Settings/SettingsIndex.vue";
import SalonShow from "@/views/Salons/SalonShow.vue";
import ReviewsIndex from "@/views/Reviews/ReviewsIndex.vue";
import OrganizationsLostUsers from "@/views/Organizations/OrganizationsLostUsers.vue";
import OrganizationTransactions from "@/views/Organizations/OrganizationTransactions.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'auth',
		component: AuthIndex,
		meta: { guest: true },
	},
	{
		path: '/dashboard',
		name: 'baseView',
		component: BaseView,
		meta: {guest: false},
		redirect: {name: 'dashboard'},
		children: [
			{
				path: '',
				name: 'dashboard',
				component: DashboardView
			},
			{
				path: 'registration-requests',
				name: 'registrationRequests',
				component: RegistrationRequestsIndex
			},
			{
				path: 'organizations',
				name: 'organizationIndex',
				component: OrganizationsIndex
			},
			{
				path: 'organizations-orders',
				name: 'organizationOrders',
				component: OrganizationOrders
			},
			{
				path: 'organization-transactions',
				name: 'organizationTransactions',
				component: OrganizationTransactions
			},
			{
				path: 'organizations-lost-users',
				name: 'organizationLostUsers',
				component: OrganizationsLostUsers
			},
			{
				path: 'organizations/:organization_id',
				name: 'organizationShow',
				component: OrganizationShow,
				props: true
			},
			{
				path: 'organizations/:organization_id/tickets',
				name: 'organization.tickets',
				component: TicketsIndex,
				props: true
			},
			{
				path: 'organizations/:organization_id/tickets/:ticket_id',
				name: 'organization.ticket.show',
				component: TicketShow,
				props: true
			},
			{
				path: 'templates',
				name: 'templatesIndex',
				component: TemplatesIndex
			},
			{
				path: 'templates/:template_id',
				name: 'templateShow',
				component: TemplateShow,
				props: true
			},
			{
				path: 'versions',
				name: 'versions',
				component: VersionsIndex,
			},
			{
				path: 'profile',
				name: 'profile',
				component: ProfileIndex
			},
			{
				path: 'salons',
				name: 'salons',
				component: SalonsIndex
			},
			{
				path: 'salons/:salon_id',
				name: 'salonShow',
				component: SalonShow,
				props: true
			},
			{
				path: 'map',
				name: 'map',
				component: SalonsMap
			},
			{
				path: 'client-orders',
				name: 'client-orders',
				component: OrdersIndex
			},
			{
				path: 'salon-requests',
				name: 'salon.requests',
				component: SalonRequestsIndex
			},
			{
				path: 'settings/:tabId?',
				name: 'settings',
				component: SettingsIndex,
				props: true
			},
			{
				path: 'reviews',
				name: 'reviews',
				component: ReviewsIndex,
			}
		]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach(async (to, from, next) => {
	if (localStorage.getItem('token') === null && to.meta.guest === false) next({ name: 'auth' })
	
	if (!store.state.user.id && localStorage.getItem('token')) await store.dispatch('getUserData')
	if (to.meta.redirectToHome && store.state.user && !to.params.noRedirectToHome) {
		next({ name: 'dashboard' })
	}
	if (to.meta.guest) {
		next()
	} else {
		if (store.state.user) {
			next()
		} else {
			next({ name: 'auth' })
		}
	}
})

export default router
