
import {defineComponent} from 'vue';
import GLoader from "@/components/Base/GLoader.vue";

export default defineComponent({
    name: "BtnLoading",
    components: {GLoader},
    props: {
        state: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false
        },
        class: {
            type: String,
            default: 'btn btn-success'
        },
        loaderSm: {
            type: Boolean,
            required: false,
            default: false
        },
        loaderColor: {
            type: String,
            default: '--bim-primary-1'
        }
    },
    computed: {
        loaderHeight() {
            if (this.loaderSm) return "14px"
            return "28px"
        },
        loaderWidth() {
            if (this.loaderSm) return "20px"
            return "40px"
        },
    }
});
