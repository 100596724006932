import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_g_text = _resolveComponent("g-text")!
  const _component_btn_loading = _resolveComponent("btn-loading")!
  const _component_g_card = _resolveComponent("g-card")!

  return (_openBlock(), _createBlock(_component_g_card, { class: "mb-3" }, {
    default: _withCtx(() => [
      _createVNode(_component_g_text, {
        label: "Скидка на 3 мес, %",
        modelValue: _ctx.settings.discount_3m,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.settings.discount_3m) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_g_text, {
        label: "Скидка на 6 мес, %",
        modelValue: _ctx.settings.discount_6m,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.settings.discount_6m) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_g_text, {
        label: "Скидка на 12 мес, %",
        modelValue: _ctx.settings.discount_12m,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.settings.discount_12m) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_btn_loading, {
        title: "Сохранить",
        state: _ctx.state.saving,
        onClick: _ctx.saveSettings
      }, null, 8, ["state", "onClick"])
    ]),
    _: 1
  }))
}