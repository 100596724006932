
import {defineComponent} from 'vue';
import {httpClient} from "@/models/HttpClient";
import GCard from "@/components/Base/GCard.vue";
import {AxiosResponse} from "axios";
import QrcodeVue from 'qrcode.vue'
import GText from "@/components/Base/GText.vue";
import router from "@/router";
import store from "@/store";
import {UserModel} from "@/models/UserModel";

export default defineComponent({
    name: "ProfileIndex",
    components: {GText, GCard, QrcodeVue},
    data() {
        return {
            enabled_2fa: false,
            v1_2fa_enable: null as null | v1_2fa_enable,
            otp: ''
        }
    },
    methods: {
        loadData() {
            httpClient
                .get(`/api/v1/2fa`)
                .then((response: AxiosResponse<{v1_2fa_index: v1_2fa_index}>) => {
                    this.enabled_2fa = response.data.v1_2fa_index.enabled_2fa
                })
        },
        twoFaEnable() {
            httpClient
                .post(`/api/v1/2fa/enable`)
                .then((response: AxiosResponse<{v1_2fa_enable: v1_2fa_enable}>) => {
                    this.v1_2fa_enable = response.data.v1_2fa_enable
                })
        },
        twoFaConfirm() {
            httpClient
                .post(`/api/v1/2fa/confirm`, {
                    otp: this.otp.replace(' ', '')
                })
                .then((response: AxiosResponse<{v1_2fa_index: v1_2fa_index}>) => {
                    this.enabled_2fa = response.data.v1_2fa_index.enabled_2fa
                    this.otp = ''
                })
        },
        twoFaDisabled() {
            httpClient
                .post(`/api/v1/2fa/disable`, {
                    otp: this.otp.replace(' ', '')
                })
                .then((response: AxiosResponse<{v1_2fa_index: v1_2fa_index}>) => {
                    this.enabled_2fa = response.data.v1_2fa_index.enabled_2fa
                    this.otp = ''
                })
        },
        logout() {
            store.state.user = new UserModel()
            localStorage.removeItem('token')
            router.push({
                name: 'auth',
            })
        }
    },
    created() {
        this.loadData()
    }
});

export interface v1_2fa_index {
    enabled_2fa: boolean
}
export interface v1_2fa_enable {
    secret_key: string
    qrcode: string
}
