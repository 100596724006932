import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "dropdown",
  class: "dropdown text-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.btnClass),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)))
    }, [
      _renderSlot(_ctx.$slots, "btn")
    ], 2),
    _createElementVNode("ul", {
      class: _normalizeClass(["dropdown-menu", {show: _ctx.isOpen, '--left': _ctx.left, '--top': _ctx.top}]),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeMenu && _ctx.closeMenu(...args)))
    }, [
      _renderSlot(_ctx.$slots, "menu")
    ], 2)
  ], 512))
}