
import {defineComponent} from "vue";
import Multiselect from '@vueform/multiselect'

export interface GSelectOption {
    value: number|string,
    name: string
}

export default defineComponent({
    emits: ['update:modelValue', 'searchChange'],
    components: {
        Multiselect
    },
    props: {
        modelValue: {
            required: true
        },
        label: {
            type: String,
            required: false
        },
        formText: {
            type: String,
            required: false
        },
        errors: {
            type: Array,
        },
        required: {
            type: Boolean,
            default: false
        },
        filterResults: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false
        },
        searchable: {
            type: Boolean,
            required: false
        },
        trackBy: {
            type: String,
            default: 'value'
        },
        selectLabel: {
            type: String,
            default: 'name'
        },
        mode: {
            type: String,
            default: 'single' // single|multiple|tags
        },
        options: {
            type: Array
        },
        canClear: {
            type: Boolean,
            default: true
        },
        canDeselect: {
            type: Boolean,
            default: false
        },
        class: {
            type: String,
            default: 'mb-3'
        },
        noResultsText: {
            type: String,
            default: 'Результаты не найдены'
        },
        noOptionsText: {
            type: String,
            default: 'Список пуст'
        },
        openDirection: {
            type: String,
            default: 'bottom'
        },
        isSearchChange: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        showOptions: {
            type: Boolean,
            default: true
        },
    },
    watch: {
        'modelValue': function (value) {
            this.value = value
            //иначе нет перерисовки multiselect
            this.$forceUpdate()
        },
        'value': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$emit('update:modelValue', newVal)
            }
        }
    },
    data() {
        return {
            value: this.modelValue
        }
    },
    methods: {
        searchChange(data: string) {
            if (this.isSearchChange) {
                this.$emit('searchChange', data)
            }
        }
    }
})
