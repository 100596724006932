import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_g_text = _resolveComponent("g-text")!
  const _component_g_select = _resolveComponent("g-select")!
  const _component_btn_loading = _resolveComponent("btn-loading")!
  const _component_g_popup = _resolveComponent("g-popup")!

  return (_openBlock(), _createBlock(_component_g_popup, {
    title: "Редактировать заявку",
    onSubmit: _ctx.save
  }, {
    body: _withCtx(() => [
      _createVNode(_component_g_text, {
        label: "Название салона",
        modelValue: _ctx.model.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_g_select, {
        label: "Город",
        modelValue: _ctx.model.city_id,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.city_id) = $event)),
        placeholder: "Найти город",
        "track-by": "name",
        "select-label": "name",
        searchable: true,
        "is-search-change": true,
        "can-clear": "",
        loading: _ctx.state.is_city_loading,
        onSearchChange: _ctx.findCity,
        options: _ctx.cities.map(c => {
                                return {
                                    value: c.id,
                                    name: c.name,
                                    label: c.name
                                }
                            })
      }, null, 8, ["modelValue", "loading", "onSearchChange", "options"]),
      _createVNode(_component_g_text, {
        label: "Адрес салона",
        modelValue: _ctx.model.address,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.address) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_g_text, {
        label: "Имя клиента",
        modelValue: _ctx.model.user_name,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.user_name) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_g_text, {
        label: "Телефон клиента",
        modelValue: _ctx.model.user_phone,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.user_phone) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_g_select, {
        label: "Статус",
        modelValue: _ctx.model.status,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.status) = $event)),
        options: _ctx.salonRequestStatuses()
      }, null, 8, ["modelValue", "options"])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_btn_loading, {
        state: _ctx.state.saving,
        title: "Сохранить"
      }, null, 8, ["state"])
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}