import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "pagination me-auto mb-0" }
const _hoisted_3 = {
  key: 0,
  class: "page-link disabled",
  tabindex: "0"
}
const _hoisted_4 = {
  key: 1,
  class: "page-link pointer",
  tabindex: "0"
}
const _hoisted_5 = ["onClick", "onKeyup"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
        return (_openBlock(), _createElementBlock("li", {
          class: _normalizeClass(["page-item", [page.selected ? 'active' : '', page.disabled ? 'disabled' : '', page.breakView ? 'disabled' : '']]),
          key: `page_${page.index}`
        }, [
          (page.breakView)
            ? (_openBlock(), _createElementBlock("a", _hoisted_3, [
                _renderSlot(_ctx.$slots, "breakViewContent", {}, () => [
                  _createTextVNode("...")
                ])
              ]))
            : (page.disabled)
              ? (_openBlock(), _createElementBlock("a", _hoisted_4, _toDisplayString(page.content), 1))
              : (_openBlock(), _createElementBlock("a", {
                  key: 2,
                  onClick: ($event: any) => (_ctx.handlePageSelected(page.index + 1)),
                  onKeyup: _withKeys(($event: any) => (_ctx.handlePageSelected(page.index + 1)), ["enter"]),
                  class: "page-link pointer",
                  tabindex: "0"
                }, _toDisplayString(page.content), 41, _hoisted_5))
        ], 2))
      }), 128))
    ])
  ]))
}