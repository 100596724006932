
import {defineComponent} from 'vue';
import {version_type, VersionsManager} from "@/models/VersionsManager";
import GCard from "@/components/Base/GCard.vue";
import VersionCreateModal from "@/views/Versions/VersionCreateModal.vue";
import GLoader from "@/components/Base/GLoader.vue";

export default defineComponent({
    name: "VersionsIndex",
    components: {GLoader, VersionCreateModal, GCard},
    data() {
        return {
            versionsManager: new VersionsManager(),
            type: {
                value: 0,
                name: 'CRM'
            } as version_type,
            showVersionCreateModal: false,
            is_loading: true
        }
    },
    created() {
        this.reloadVersions()
    },
    methods: {
        reloadVersions() {
            this.is_loading = true
            this.versionsManager
                .loadVersions()
                .finally(() => {
                    this.is_loading = false
                })
        },
        openVersionCreateModal() {
            this.showVersionCreateModal = true
        },
        closeVersionCreateModal() {
            this.showVersionCreateModal = false
            this.reloadVersions()
        }
    }
});
