
import { defineComponent } from 'vue';
export default defineComponent({
    data() {
        return {
            menu: [
                {name: 'registrationRequests', label: 'Заявки'},
                {name: 'organizationIndex', label: 'Организации'},
                {name: 'salons', label: 'Салоны'},
                {name: 'templatesIndex', label: 'Шаблоны'},
                {name: 'versions', label: 'Версии'},
                {name: 'profile', label: 'Профиль'},
                {name: 'client-orders', label: 'Записи'},
                {name: 'settings', label: 'Настройки'},
            ],
            isOpen: true
        }
    }
});
