
import {defineComponent} from 'vue';
import GCard from "@/components/Base/GCard.vue";
import {httpClient} from "@/models/HttpClient";
import {AxiosResponse} from "axios";
import {SiteTemplate} from "@/models/Interfaces";
import CreateTemplateVersionPopup from "@/views/Templates/popups/CreateTemplateVersionPopup.vue";
import GText from "@/components/Base/GText.vue";
import GChecks from "@/components/Base/GChecks.vue";

export default defineComponent({
    name: "TemplateShow",
    components: {GChecks, GText, CreateTemplateVersionPopup, GCard},
    created() {
        this.loadTemplate()
    },
    props: {
        template_id: {
            type: String
        }
    },
    data() {
        return {
            template: {
                color_themes: [] as Array<string>
            } as SiteTemplate,
            showVersionPopup: false
        }
    },
    methods: {
        loadTemplate() {
            httpClient.get(`/api/site-templates/${this.template_id}`)
                .then((response: AxiosResponse<{site_template: SiteTemplate}>) => {
                    this.template = response.data.site_template
                })
        },
        openVersionPopup() {
            this.showVersionPopup = true
        },
        closeVersionPopup() {
            this.showVersionPopup = false
            this.loadTemplate()
        },
        updateSiteTemplate() {
            let data = new FormData()
            data.append('_method', 'patch')
            data.append('action', 'data')
            data.append('name', this.template.name)
            data.append('description', this.template.description)

            this.template.color_themes.forEach(color => {
                data.append('color_themes[]', color)
            })

            data.append('active', this.template.active ? "1" : "0")
            if (this.template.photo.new_file)
                data.append('photo', this.template.photo.new_file)

            httpClient.post(`/api/site-templates/${this.template_id}`, data)
                .then((response: AxiosResponse<{site_template: SiteTemplate}>) => {
                    this.template = response.data.site_template
                })
        },
        setImageSelect() {
            let files = this.$refs.imageSelect as HTMLInputElement
            if (files.files && files.files[0]) {
                this.template.photo.new_file = files.files[0]
                this.template.photo.url = URL.createObjectURL(files.files[0])
            }
        },
        deleteVersion(id: number) {
            if (confirm('Удалить версию?'))
                httpClient.delete(`/api/site-templates/${this.template.id}/site-template-versions/${id}`)
                    .then(() => {
                        this.loadTemplate()
                    })
        },
        deleteColor(key:number) {
            this.template.color_themes.splice(key, 1)
        }
    }
});
