
import {defineComponent} from 'vue';
import GCard from "@/components/Base/GCard.vue";
import {City, Pagination, PaginationMeta} from "@/models/Interfaces";
import {httpClient} from "@/models/HttpClient";
import {AxiosResponse} from "axios";
import GPaginate from "@/components/Base/GPaginate.vue";
import FullPageLoader from "@/components/FullPageLoader.vue";
import SalonRequestsUpdate from "@/views/Salons/Requests/SalonRequestsUpdate.vue";
import {salonRequestStatuses} from "@/defData";

export default defineComponent({
    name: "SalonRequestsIndex",
    components: {SalonRequestsUpdate, FullPageLoader, GPaginate, GCard},
    data() {
        return {
            models: [] as Array<ISalonRequest>,
            meta: {
                per_page: 10,
                current_page: 1
            } as PaginationMeta,
            state: {
                loading: false,
                edit: false
            },
            model: {} as ISalonRequest,
            salonRequestStatuses
        }
    },
    methods: {
        setPage(page: number) {
            this.meta.current_page = page
            this.loadData()
        },
        loadData() {
            this.state.loading = true
            httpClient
                .get(`/api/salon-requests`, {
                    params: {
                        page: this.meta.current_page,
                        per_page: this.meta.per_page
                    }
                })
                .then((response: AxiosResponse<Pagination<ISalonRequest>>) => {
                    this.models = response.data.models
                    this.meta.count = response.data.meta.count
                    this.meta.total_pages = response.data.meta.total_pages
                })
                .finally(() => {
                    this.state.loading = false
                })
        },
        openEdit(model: ISalonRequest) {
            this.model = model
            this.state.edit = true
        },
        closeEdit() {
            this.state.edit = false
            this.loadData()
        }
    },
    created() {
        this.loadData()
    }
});

export interface ISalonRequest {
    id: number
    name: string
    user_name: string
    user_phone: string
    city_id: number
    city: City
    address: string
    salon_id: number|null
    status: number
}
