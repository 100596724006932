import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-label"
}
const _hoisted_2 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_3 = {
  key: 1,
  class: "form-text"
}
const _hoisted_4 = {
  key: 2,
  class: "form-text"
}
const _hoisted_5 = {
  key: 3,
  class: "invalid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiselect = _resolveComponent("multiselect")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$props.class)
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_multiselect, {
      class: _normalizeClass({'is-invalid': _ctx.errors}),
      disabled: _ctx.disabled,
      placeholder: _ctx.placeholder,
      "track-by": _ctx.trackBy,
      options: _ctx.options,
      label: _ctx.selectLabel,
      mode: _ctx.mode,
      "can-clear": _ctx.canClear,
      required: _ctx.required,
      "filter-results": _ctx.filterResults,
      "can-deselect": _ctx.canDeselect,
      searchable: _ctx.searchable,
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      "no-results-text": _ctx.noResultsText,
      "no-options-text": _ctx.noOptionsText,
      openDirection: _ctx.openDirection,
      loading: _ctx.loading,
      showOptions: _ctx.showOptions,
      onSearchChange: _ctx.searchChange
    }, _createSlots({ _: 2 }, [
      (_ctx.$slots.tag)
        ? {
            name: "tag",
            fn: _withCtx(({ option, handleTagRemove }) => [
              _renderSlot(_ctx.$slots, "tag", {
                option: option,
                remove: handleTagRemove
              })
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["class", "disabled", "placeholder", "track-by", "options", "label", "mode", "can-clear", "required", "filter-results", "can-deselect", "searchable", "modelValue", "no-results-text", "no-options-text", "openDirection", "loading", "showOptions", "onSearchChange"]),
    (_ctx.formText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.formText), 1))
      : _createCommentVNode("", true),
    (_ctx.$slots.formText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "formText")
        ]))
      : _createCommentVNode("", true),
    (_ctx.errors)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.errors.join(', ')) + ". ", 1))
      : _createCommentVNode("", true)
  ], 2))
}