
import {defineComponent} from 'vue';
import {httpClient, LaravelErrorResponse} from "@/models/HttpClient";
import {Pagination, PaginationMeta} from "@/models/Interfaces";
import {AxiosError, AxiosResponse} from "axios";
import GCard from "@/components/Base/GCard.vue";
import GPaginate from "@/components/Base/GPaginate.vue";
import store from "@/store";
import {ToastTypes} from "@/components/ToastService/ToastsService";
import FullPageLoader from "@/components/FullPageLoader.vue";
import {subscriptionTransactionType} from "@/defData";
import GText from "@/components/Base/GText.vue";

export default defineComponent({
    name: "OrganizationTransactions",
    components: { GText, FullPageLoader, GPaginate, GCard},
    data() {
        return {
            meta: {
                current_page: 1,
                per_page: 10,
                total: 0
            } as PaginationMeta,
            transactions: [] as Array<ITransaction>,
            state: {
                loading: false
            },
            subscriptionTransactionType,
            filter: {
                spaceId: null,
                status: 'all'
            }
        }
    },
    computed: {
        sum() {
            let sum = 0
            this.transactions.forEach(t => {
                if (subscriptionTransactionType[t.status].to_sum)
                    sum += t.value
            })
            return sum
        }
    },
    methods: {
        setPage(page: number) {
            this.meta.current_page = page
            this.load()
        },
        load() {
            this.state.loading = true
            httpClient
                .get(`/api/app/v1/organization-transactions`, {
                    params: {
                        page: this.meta.current_page,
                        per_page: this.meta.per_page,
                        ...this.filter
                    }
                })
                .then((response: AxiosResponse<Pagination<ITransaction>>) => {
                    this.meta.total = response.data.meta.total
                    this.meta.total_pages = response.data.meta.total_pages
                    this.transactions = response.data.models
                })
                .catch((error: AxiosError<LaravelErrorResponse>) => {
                    store.state.toasts.addToast({
                        title: error?.response?.data.message ?? 'Ошибка!',
                        type: ToastTypes.error
                    })
                })
                .finally(() => {
                    this.state.loading = false
                })
        }
    },
    created() {
        this.load()
    },
    watch: {
        'meta.per_page' : function () {
            this.setPage(1)
        },
        'filter.spaceId' : function () {
            this.setPage(1)
        },
        'filter.status' : function () {
            this.setPage(1)
        },
    }
});

export interface ITransaction {
    id: string
    value: number
    comment: string
    enrolled: boolean
    organization_id: number
    status: number
    created_at: string
    updated_at: string
}
