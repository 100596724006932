import { vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-check-label text-nowrap"
}
const _hoisted_2 = {
  key: 1,
  class: "form-text"
}
const _hoisted_3 = {
  key: 2,
  class: "invalid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$props.class)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["form-check", {'form-switch': _ctx.formSwitch}])
    }, [
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass(["form-check-input", {'is-invalid': _ctx.errors}]),
        type: "checkbox",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
      }, null, 2), [
        [_vModelCheckbox, _ctx.value]
      ]),
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      (_ctx.formText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.formText), 1))
        : _createCommentVNode("", true),
      (_ctx.errors)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.errors.join(', ')) + ". ", 1))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}