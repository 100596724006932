import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "toast-container position-fixed bottom-0 end-0 p-3" }
const _hoisted_2 = ["onMouseenter", "onMouseleave"]
const _hoisted_3 = { class: "toast-header" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "toast-body"
}
const _hoisted_7 = {
  key: 0,
  class: "pb-2"
}
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toasts.list, (toast) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `toast_${toast.id}`,
        class: _normalizeClass(["toast show", `toast-${toast.type}`]),
        onMouseenter: ($event: any) => (_ctx.rejectAutoClose(toast.id)),
        onMouseleave: ($event: any) => (_ctx.startAutoClose(toast.id))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            innerHTML: toast.title
          }, null, 8, _hoisted_4),
          _createElementVNode("a", {
            class: "btn-close btn-close-white me-2 m-auto pointer",
            onClick: ($event: any) => (_ctx.close(toast.id))
          }, null, 8, _hoisted_5)
        ]),
        (toast.body || toast.route)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (toast.body)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(toast.body), 1))
                : _createCommentVNode("", true),
              (toast.route)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_router_link, {
                      replace: "",
                      to: toast.route,
                      class: _normalizeClass(["btn btn-sm", `btn-${toast.type}`]),
                      onClick: ($event: any) => (_ctx.close(toast.id))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Открыть ")
                      ]),
                      _: 2
                    }, 1032, ["to", "class", "onClick"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ], 42, _hoisted_2))
    }), 128))
  ]))
}