import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-header" }
const _hoisted_2 = { class: "fs-16 fw-600" }
const _hoisted_3 = {
  key: 0,
  class: "modal-body"
}
const _hoisted_4 = {
  key: 2,
  class: "modal-footer"
}
const _hoisted_5 = {
  key: 0,
  class: "modal-backdrop fade show"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_g_loader = _resolveComponent("g-loader")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["modal fade", {show: _ctx.show, 'd-block': !_ctx.hide}]),
      onMousedown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeOutSide && _ctx.closeOutSide(...args)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["modal-dialog", _ctx.modalType])
      }, [
        _createElementVNode("form", {
          class: "modal-content",
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('submit')), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            })
          ]),
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_g_loader)
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["modal-body", _ctx.bodyClass])
              }, [
                _renderSlot(_ctx.$slots, "body"),
                _renderSlot(_ctx.$slots, "default")
              ], 2)),
          (_ctx.$slots.footer && !_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "footer")
              ]))
            : _createCommentVNode("", true)
        ], 32)
      ], 2)
    ], 34), [
      [_vShow, !_ctx.hide]
    ]),
    (!_ctx.hide)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5))
      : _createCommentVNode("", true)
  ]))
}