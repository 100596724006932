import {GFile, Pagination, User} from "@/models/Interfaces";
import {AxiosResponse} from "axios";
import {httpClient} from "@/models/HttpClient";

export class TicketsManager {
	
	constructor(organization_id: number|string) {
		this.organization_id = organization_id
	}
	
	organization_id = 0 as number|string
	ticket_id = 0 as number|string
	
	index(): Promise<AxiosResponse<{ tickets_index: ITicketsIndex }>> {
		return httpClient
			.get(`/api/organizations/${this.organization_id}/tickets`)
	}
	
	setTicketId(ticket_id: number|string) {
		this.ticket_id = ticket_id
	}
	
	show() : Promise<AxiosResponse<{ ticket_messages_index: ITicketMessagesIndex }>> {
		return httpClient
			.get(`/api/organizations/${this.organization_id}/tickets/${this.ticket_id}`)
	}
	
	sendTextMessage(message: string) : Promise<AxiosResponse<{ticket_message_resource: ITicketMessage}>> {
		return httpClient
			.post(`/api/organizations/${this.organization_id}/tickets/${this.ticket_id}`, {
				message: message
			})
	}
	
	sendFormDataMessage(file: File, type: string) : Promise<AxiosResponse<{ticket_message_resource: ITicketMessage}>> {
		const data = new FormData()
		data.append('document', file)
		data.append('type', type)
		
		return httpClient
			.post(`/api/organizations/${this.organization_id}/tickets/${this.ticket_id}`, data)
	}
	
	closeTicket() {
		return httpClient
			.post(`/api/organizations/${this.organization_id}/tickets/${this.ticket_id}/close`)
	}
	
	openTicket() {
		return httpClient
			.post(`/api/organizations/${this.organization_id}/tickets/${this.ticket_id}/open`)
	}
	
	prettyBytes(bytes: number) {
		if (bytes === 0) return '0 Bytes'
		
		const k =  1024
		const dm = 2
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB', 'BB']
		const i = Math.floor(Math.log(bytes) / Math.log(k))
		
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
	}
}

export interface ITicketMessagesIndex {
	ticket: ITicket
	messages: Array<ITicketMessage>
}

export interface ITicketMessage {
	id: number
	type: 'text'|'photo'|'document'
	photo: IChatFile|null
	document: IChatFile|null
	message: string
	user: User
	created_at: string
}

export interface ITicketsIndex {
	tickets: Pagination<ITicket>
	types: Array<ITicketType>
}

export interface ITicketType {
	id: number
	locale: string
}

export interface ITicket {
	id: number
	type: number
	type_locale: string
	user: User
	is_closed: boolean
}

export interface IChatFile extends GFile {
	name: string
	size: number
	file: File
	extension: string
}
