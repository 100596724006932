
import {defineComponent} from "vue";

export default defineComponent({
    emits: ['update:modelValue', 'focus', 'click'],
    props: {
        label: {
            type: String,
            required: false
        },
        class: {
            type: String,
            required: false,
            default: 'mb-3'
        },
        placeholder: {
            type: String,
            required: false
        },
        modelValue: {
            required: true
        },
        errors: {
            type: Array,
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        // type: {
        //     required: false,
        //     default: 'text'
        // },
        step: {
            required: false,
            default: null
        },
        min: {
            required: false,
            default: null
        },
        max: {
            required: false,
            default: null
        },
        maxlength: {
            required: false,
            default: null
        },
        formText: {
            required: false,
            default: null
        },
        dataMaska: {
            required: false,
            default: null
        },
        masked: {
            type: Boolean,
            default: false
        },
        textarea: {
            type: Boolean,
            default: false
        },
        number: {
            type: Boolean,
            default: false
        },
        password: {
            type: Boolean,
            default: false
        },
        textareaAutoHeight: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        'modelValue': function (value) {
            this.value = value
        },
        'value': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$emit('update:modelValue', newVal)
            }
        }
    },
    computed: {
        type() : string {
            if (this.masked) return 'masked'
            if (this.textarea) return 'textarea'
            if (this.number) return 'number'
            if (this.password) return 'password'
            return 'text'
        }
    },
    data() {
        return {
            value: this.modelValue
        }
    },
    mounted() {
        this.resize()
    },
    methods: {
        focus() {
            this.$emit('focus', this.$refs[this.type])
        },
        input() {
            this.resize()
        },
        resize() {
            if (this.textareaAutoHeight) {
                const textarea = (this.$refs.textarea as HTMLInputElement);
                textarea.style.height = "20px"
                const dt = this.maxlength ? 10 : 2;
                textarea.style.height = textarea.scrollHeight + dt + 'px';
            }
        },
        click() {
            this.$emit('click', this.$refs[this.type])
        }
    }
})
