
import {defineComponent} from 'vue';
import {httpClient} from "@/models/HttpClient";
import {AxiosResponse} from "axios";
import GCard from "@/components/Base/GCard.vue";
import { Pagination, PaginationMeta, RegistrationRequest} from "@/models/Interfaces";
import CreateOrganizationModal from "@/views/RegistrationRequests/CreateOrganizationModal.vue";
import GPaginate from "@/components/Base/GPaginate.vue";

export default defineComponent({
    name: "RegistrationRequestsIndex",
    components: {GPaginate, CreateOrganizationModal, GCard},
    created() {
        this.loadRegistrationRequests()
    },
    data() {
        return {
            models: [] as Array<RegistrationRequest>,
            meta: {
                current_page: 1,
                per_page: 20,
            } as PaginationMeta,
            showCreateOrganizationModal: false,
            registration_request_id: 0
        }
    },
    methods: {
        setPage(page:number) {
            this.meta.current_page = page
            this.loadRegistrationRequests()
        },
        loadRegistrationRequests() {
            httpClient
                .get(`/api/registration-requests`, {
                    params: {
                        page: this.meta.current_page,
                        per_page: this.meta.per_page
                    }
                })
                .then((response: AxiosResponse<Pagination<RegistrationRequest>>) => {
                    this.models = response.data.models
                    this.meta = response.data.meta
                })
        },
        openCreateOrganizationModal(model: RegistrationRequest) {
            this.registration_request_id = model.id
            this.showCreateOrganizationModal = true
        },
        closeCreateOrganizationModal() {
            this.showCreateOrganizationModal = false
            this.loadRegistrationRequests()
        },
        setEmployee(registrationRequest: number) {
            httpClient
                .post(`/api/registration-requests/${registrationRequest}/set-employee`, {
                    params: {
                        page: this.meta.current_page,
                        per_page: this.meta.per_page
                    }
                })
                .then(() => {
                    this.loadRegistrationRequests()
                })
        }
    }
});
