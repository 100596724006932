
import {defineComponent} from 'vue';
import GText from "@/components/Base/GText.vue";
import {httpClient} from "@/models/HttpClient";
import BtnLoading from "@/components/BtnLoading.vue";
import GCard from "@/components/Base/GCard.vue";

export default defineComponent({
    name: "SystemSettingsIndex",
    components: {GCard, BtnLoading, GText},
    data() {
        return {
            settings: {
                discount_3m: 0,
                discount_6m: 0,
                discount_12m: 0,
            },
            state: {
                saving: false
            }
        }
    },
    methods: {
        getSettings() {
            httpClient
                .get(`/api/v1/system-settings`)
                .then(response => {
                    this.settings = response.data
                })
        },
        saveSettings() {
            this.state.saving = true
            httpClient
                .post(`/api/v1/system-settings`, {
                    settings: {
                        discount_3m: this.settings.discount_3m,
                        discount_6m: this.settings.discount_6m,
                        discount_12m: this.settings.discount_12m
                    }
                })
                .catch(() => {
                    alert('Ошибка')
                })
                .finally(() => {
                    this.state.saving = false
                })
        }
    },
    created() {
        this.getSettings();
    }
});
