
import {defineComponent} from 'vue';
import {httpClient} from "@/models/HttpClient";
import {AxiosResponse} from "axios";
import {Organization, Pagination} from "@/models/Interfaces";
import GCard from "@/components/Base/GCard.vue";

export default defineComponent({
    name: "OrganizationsLostUsers",
    components: {GCard},
    data() {
        return {
            models: [] as Array<Organization>,
            per_page: 100,
            total: 0,
            lostUsers: [] as Array<ILostUser>
        }
    },
    methods: {
        async loadLostUsersOrganizations() {
            for (const row of this.models) {
                await this.loadLostUsers(row.id)
            }
        },
        loadLostUsers(organization_id: number) {
            return httpClient
                .get(`/api/app/v1/organizations/${organization_id}/users/lost`)
                .then((response: AxiosResponse<{users: Array<ILostUser>}>) => {
                    response.data.users.forEach(user => {
                        this.lostUsers.push({
                            ...user,
                            organization_id: organization_id
                        })
                    })
                })
        },
        loadOrganizations() {
            httpClient
                .get(`/api/organizations`, {
                    params: {
                        page: 1,
                        per_page: this.per_page,
                        status: 6
                    }
                })
                .then((response: AxiosResponse<Pagination<Organization>>) => {
                    this.models = response.data.models
                    this.total = response.data.meta.total
                })
        },
        async checkAuth() {
            for (const user of this.lostUsers) {
                await httpClient
                    .get(`/api/app/v1/organizations/${user.organization_id}/users/check-auth/${user.phone}`)
                    .then((response: AxiosResponse<{called_at: string}>) => {
                        user.last_auth = response.data.called_at ?? ''
                    })
            }
        }
    },
    created() {
        this.loadOrganizations()
    },
});

export interface ILostUser {
    id: number
    phone: string
    firstname: string
    pets_count: number
    pets_name: string
    organization_id: number
    last_auth: string
    firebase_tokens_count: number
}
