import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_g_text = _resolveComponent("g-text")!
  const _component_g_select = _resolveComponent("g-select")!
  const _component_g_popup = _resolveComponent("g-popup")!

  return (_openBlock(), _createBlock(_component_g_popup, {
    title: "Создать организацию",
    onClose: _ctx.close,
    onSubmit: _ctx.createOrganization
  }, {
    body: _withCtx(() => [
      _createVNode(_component_g_text, {
        label: "Номер заявки",
        disabled: "",
        modelValue: _ctx.model.registration_request_id,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.registration_request_id) = $event)),
        errors: _ctx.errors['registration_request_id']
      }, null, 8, ["modelValue", "errors"]),
      _createVNode(_component_g_text, {
        label: "Название",
        required: "",
        modelValue: _ctx.model.name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.name) = $event)),
        errors: _ctx.errors['name']
      }, null, 8, ["modelValue", "errors"]),
      _createVNode(_component_g_select, {
        label: "Тип",
        required: "",
        modelValue: _ctx.model.type,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.type) = $event)),
        options: [{value: 'single', name: 'Частный'},{value: 'salon', name: 'Салон'},],
        errors: _ctx.errors['type']
      }, null, 8, ["modelValue", "errors"]),
      _createVNode(_component_g_text, {
        label: "Кол во мастеров",
        type: "number",
        modelValue: _ctx.model.masters_count,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.masters_count) = $event)),
        errors: _ctx.errors['masters_count']
      }, null, 8, ["modelValue", "errors"]),
      _createElementVNode("button", {
        class: "btn btn-success",
        disabled: _ctx.state.isCreating
      }, " Создать ", 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["onClose", "onSubmit"]))
}