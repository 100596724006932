import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = { class: "form-label" }
const _hoisted_3 = ["required"]
const _hoisted_4 = {
  key: 0,
  class: "invalid-feedback"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("input", {
      class: _normalizeClass(["form-control", {'is-invalid': _ctx.errors}]),
      type: "file",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadFile && _ctx.uploadFile(...args))),
      ref: "file",
      required: _ctx.required
    }, null, 42, _hoisted_3),
    (_ctx.errors)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.errors.join(', ')) + ". ", 1))
      : _createCommentVNode("", true),
    (_ctx.type === 'image')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.value?.url)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "mw-100",
                src: _ctx.value.url
              }, null, 8, _hoisted_6))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}