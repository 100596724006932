
import {defineComponent} from 'vue';
import DG from "2gis-maps";
import {httpClient} from "@/models/HttpClient";
import {AxiosResponse} from "axios";
import {ISalon} from "@/views/Salons/SalonsIndex.vue";

export default defineComponent({
    name: "SalonsMap",
    methods: {
        loadData() {
            httpClient
                .get(`/api/salons-map`)
                .then((response: AxiosResponse<Array<ISalon>>) => {
                    response.data.forEach((row) => {
                        DG.marker([row.map_lat, row.map_long], {id: row.id}).addTo(this.map)
                    })
                })
        }
    },
    data() {
        return {
            map: null as null | any,
        }
    },
    mounted() {
        this.map = new DG.map(this.$el, {
            center: ['51.66073', '39.200594'],
            zoom: 9
        })
    },
    created() {
        this.loadData()
    }
});
