
import {defineComponent} from 'vue';
import GPopup from "@/components/Base/GPopup.vue";
import GText from "@/components/Base/GText.vue";
import GSelect from "@/components/Base/GSelect.vue";
import {httpClient, LaravelErrorResponse, LaravelErrorResponseErrors} from "@/models/HttpClient";
import {AxiosError} from "axios";

export default defineComponent({
    name: "CreateOrganizationModal",
    emits: ['close'],
    components: {GSelect, GText, GPopup},
    props: {
        registration_request_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            model: {
                registration_request_id: this.registration_request_id,
                name: '',
                type: '',
                masters_count: 1,
            },
            errors: {} as LaravelErrorResponseErrors,
            state: {
                isCreating: false
            }
        }
    },
    computed: {
        nameError() {
            if (this.model.name.includes('"'))
                return ['Ошибка, нельзя использовать "']
            return null
        }
    },
    methods: {
        createOrganization() {
            this.errors = {}
            if (this.model.name.includes('"')) {
                this.errors['name'] = [
                    'Ошибка, нельзя использовать "'
                ]
            } else {
                this.state.isCreating = true
                httpClient
                    .post(`/api/organizations`, this.model)
                    .then(() => {
                        alert('Успешно создана')
                        this.close()
                    })
                    .catch((errors: AxiosError<LaravelErrorResponse>) => {
                        console.warn(errors.request)
                    })
                    .finally(() => {
                        this.errors = {}
                        this.state.isCreating = false
                    })
            }
        },
        close() {
            this.$emit('close')
        }
    }
});
