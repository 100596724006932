import Axios from 'axios'

const token:string = localStorage.getItem('token') || ''

export const httpClient = Axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_PROD_URL : process.env.VUE_APP_API_DEV_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
    }
})



if (token) {
    httpClient.defaults.headers.common.Authorization = token
}


export interface LaravelErrorResponse {
    errors: LaravelErrorResponseErrors
    message: string
}

export interface LaravelErrorResponseErrors {
    [key:string]: Array<string>
}
