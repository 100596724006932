import {ApiResponse, AuthData, DashboardData, UserI} from "@/models/Interfaces";
import { httpClient} from "@/models/HttpClient";
import {AxiosResponse} from "axios";

export class UserModel implements UserI {
	id = 0
	name = ''
	email = ''
	auth(email: string, password: string, otp: string): Promise<ApiResponse<{
		auth_data: AuthData
	}>> {
		return httpClient.post(`/api/auth`, {
			email: email,
			password: password,
			otp: otp
		})
	}
	
	me() : Promise<boolean> {
		return httpClient.get(`/api/profile`)
			.then((response: AxiosResponse<{user_profile: UserI}>) => {
				const data = response.data.user_profile
				
				Object.keys(data).forEach((key) => {
					(this as Record<string, unknown>)[key] = data[key as keyof UserI];
				});
				return true
			})
	}
	
	loadDashboard() : Promise<ApiResponse<{
		data: DashboardData
	}>> {
		return httpClient.get(`/api/dashboard`)
	}
}
