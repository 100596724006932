
import {defineComponent} from 'vue';
import GCard from "@/components/Base/GCard.vue";
import {httpClient} from "@/models/HttpClient";
import {AxiosResponse} from "axios";
import {Organization, Pagination} from "@/models/Interfaces";
import GText from "@/components/Base/GText.vue";
import GChecks from "@/components/Base/GChecks.vue";
import GLoader from "@/components/Base/GLoader.vue";

export default defineComponent({
    name: "OrganizationOrders",
    components: {GLoader, GChecks, GText, GCard},
    data() {
        return {
            models: [] as Array<Organization>,
            per_page: 100,
            total: 0,
            date_from: '2023-01-01',
            date_to: '2024-01-01',
            only_client_orders: false,
            statuses: {
                4: true,
            } as {[key:number|string]: boolean}
        }
    },
    computed: {
        allCount() {
            let cnt = 0;
            this.models.forEach(m => {
                if (m.order_count) cnt += m.order_count
            })
            return cnt
        }
    },
    methods: {
        setDateFrom(e: InputEvent){
            this.date_from = (e.target as HTMLInputElement).value
        },
        setDateTo(e: InputEvent) {
            this.date_to = (e.target as HTMLInputElement).value
        },
        async loadOrderCounts() {
            this.models.forEach(model => {
                model.order_count = 0
            })

            let statuses = [] as Array<string>;

            Object.keys(this.statuses).forEach((key: string) => {
                if (this.statuses[key]) statuses.push(key)
            })

            // app/v1/organizations/  orders/count
            for (const row of this.models) {
                if (row.status === 'work' || row.status === 'k8s') {
                    try {
                        row.is_load_stat = true
                        httpClient
                            .get(`/api/app/v1/organizations/${row.id}/orders/count`, {
                                params: {
                                    statuses: statuses,
                                    date_from: this.date_from,
                                    date_to: this.date_to,
                                    only_client_orders: this.only_client_orders ? 1 : 0
                                }
                            })
                            .then((response: AxiosResponse<{
                                v1_system_order_count: {
                                    counts: Array<{
                                        status_id: number
                                        orders_count: number
                                    }>
                                }
                            }>) => {
                                let cnt = 0;
                                response.data.v1_system_order_count.counts.forEach(r => {
                                    cnt += r.orders_count
                                })
                                row.order_count = cnt
                            })
                            .finally(() => {
                                row.is_load_stat = false
                            })
                    } catch (e) {
                        row.is_load_stat = false
                        console.log(e)
                    }
                }
            }
        },
        loadOrganizations() {
            httpClient
                .get(`/api/organizations`, {
                    params: {
                        page: 1,
                        per_page: this.per_page,
                        status: 6
                    }
                })
                .then((response: AxiosResponse<Pagination<Organization>>) => {
                    this.models = response.data.models
                    this.total = response.data.meta.total
                })
        }
    },
    created() {
        this.loadOrganizations()
    },
});
