
import {defineComponent} from 'vue';
import GPopup from "@/components/Base/GPopup.vue";
import {httpClient} from "@/models/HttpClient";
import GText from "@/components/Base/GText.vue";
import GFilePicker from "@/components/Base/GFilePicker.vue";
import {GFile} from "@/models/Interfaces";

export default defineComponent({
    emits: ['close'],
    name: "CreateTemplatePopup",
    components: {GFilePicker, GText, GPopup},
    data() {
        return {
            name: '',
            photo: {} as GFile,
        }
    },
    methods: {
        createTemplate() {
            let data = new FormData();
            data.append('name', this.name)
            if (this.photo?.new_file)
                data.append('photo', this.photo.new_file)

            httpClient.post(`/api/site-templates`, data)
                .then(() => {
                    this.close()
                })
        },
        close() {
            this.$emit('close')
        }
    }
});
