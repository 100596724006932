
import {defineComponent, PropType} from 'vue';
import GCard from "@/components/Base/GCard.vue";
import {httpClient} from "@/models/HttpClient";
import GLoader from "@/components/Base/GLoader.vue";
import {AxiosResponse} from "axios";
import GSelect from "@/components/Base/GSelect.vue";
import {IPetType, IService, IServiceGroup} from "@/models/Interfaces";
import ServiceModal from "@/views/Settings/Modals/ServiceModal.vue";

export default defineComponent({
    name: "ServicesIndex",
    components: {GSelect, ServiceModal, GLoader, GCard},
    props: {
        petTypes: {
            type: Object as PropType<Array<IPetType>>,
            required: true
        }
    },
    data() {
        return {
            state: {
                loadingServiceGroups: false,
                loadingServices: false,
                showServiceGroupModal: false,
                showServiceModal: false
            },
            serviceGroups: [] as Array<IServiceGroup>,
            services: [] as Array<IService>,
            service: {} as IService,
            filter: {
                serviceGroupId: null,
                petTypeId: null
            }
        }
    },
    computed: {
        filteredServices() : Array<IService> {
            return this.services.filter(row =>
                (row.pet_type_id === this.filter.petTypeId || !this.filter.petTypeId)
                &&
                (row.service_group_id === this.filter.serviceGroupId || !this.filter.serviceGroupId)
            )
        }
    },
    methods: {
        loadServiceGroups() {
            this.state.loadingServiceGroups = true
            httpClient
                .get(`/api/v1/service-groups`)
                .then((response: AxiosResponse<{data: Array<IServiceGroup>}>) => {
                    this.serviceGroups = response.data.data
                })
                .catch((e) => {
                    console.warn(e)
                })
                .finally(() => {
                    this.state.loadingServiceGroups = false
                })
        },
        loadServices() {
            this.state.loadingServices = true
            httpClient
                .get(`/api/v1/services`)
                .then((response: AxiosResponse<{data: Array<IService>}>) => {
                    this.services = response.data.data
                })
                .catch((e) => {
                    console.warn(e)
                })
                .finally(() => {
                    this.state.loadingServices = false
                })
        },
        openServiceModal(model?: IService) {
            if (model)
                this.service = model
            this.state.showServiceModal = true
        },
        closeServiceModal() {
            this.state.showServiceModal = false
            this.loadServices()
        }
    },
    created() {
        this.loadServiceGroups()
        this.loadServices()
    }
});
