
import {defineComponent} from 'vue';
import ServiceGroupsIndex from "@/views/Settings/ServiceGroupsIndex.vue";
import ServicesIndex from "@/views/Settings/ServicesIndex.vue";
import {httpClient} from "@/models/HttpClient";
import {AxiosResponse} from "axios";
import {IPetType} from "@/models/Interfaces";
import SystemSettingsIndex from "@/views/Settings/SystemSettingsIndex.vue";
import router from "@/router";

export default defineComponent({
    name: "SettingsIndex",
    components: {SystemSettingsIndex, ServicesIndex, ServiceGroupsIndex},
    props: {
        tabId: {
            type: String
        }
    },
    data() {
        return {
            tab: this.tabId ? parseInt(this.tabId) : 0,
            tabs: [
                { id:0, name: 'Скидки' },
                { id:1, name: 'Группы услуг' },
                { id:2, name: 'Услуги' },
            ],
            petTypes: [] as Array<IPetType>
        }
    },
    methods: {
        setTabId(id: number) {
            router.push({
                name: 'settings',
                params: {
                    tabId: id
                }
            })
        },
        getPetTypes() {
            httpClient
                .get(`/api/v1/pet-types`)
                .then((response: AxiosResponse<{data: Array<IPetType>}>) => {
                    this.petTypes = response.data.data
                })
        },
    },
    watch: {
        tabId: function () {
            this.tab = this.tabId ? parseInt(this.tabId) : 0
        }
    },
    created() {
        this.getPetTypes()
    }
});
