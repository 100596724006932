
import {defineComponent} from 'vue';
import GCard from "@/components/Base/GCard.vue";
import GLoader from "@/components/Base/GLoader.vue";
import GPaginate from "@/components/Base/GPaginate.vue";
import {City, Organization, Pagination, PaginationMeta} from "@/models/Interfaces";
import {httpClient} from "@/models/HttpClient";
import {AxiosResponse} from "axios";
import GSelect from "@/components/Base/GSelect.vue";
import FullPageLoader from "@/components/FullPageLoader.vue";

export default defineComponent({
    name: "SalonsIndex",
    components: {FullPageLoader, GSelect, GPaginate, GLoader, GCard},
    props: {
        organization_id: {
            type: [String, Number]
        }
    },
    data() {
        return {
            is_loading: false,
            is_city_loading: false,
            meta: {
                current_page: 1,
                per_page: 10
            } as PaginationMeta,
            models: [] as Array<ISalon>,
            filters: {
                city_id: null as number | null,
                organization_id: this.organization_id as number | null,
            },
            cities: [] as Array<City>,
        }
    },
    methods: {
        setPage(page: number) {
            this.meta.current_page = page
            this.loadData()
        },
        loadData() {
            this.is_loading = true
            httpClient
                .get(`/api/v1/salons`, {
                    params: {
                        page: this.meta.current_page,
                        per_page: this.meta.per_page,
                        city_id: this.filters.city_id,
                        organization_id: this.filters.organization_id
                    }
                })
                .then((response: AxiosResponse<Pagination<ISalon>>) => {
                    this.meta.total_pages = response.data.meta.total_pages
                    this.meta.count = response.data.meta.count

                    this.models = response.data.models
                })
                .finally(() => {
                    this.is_loading = false
                })
        },
        deleteSalon(salon: ISalon) {
            if (confirm('Удалить?')) {
                httpClient
                    .delete(`/api/v1/salons/${salon.id}`)
                    .then(() => {
                        this.loadData()
                    })
            }
        },
        findCity(city_name: string) {
            httpClient
                .get(`/api/app/v1/cities`, {
                    params: {
                        search_name: city_name,
                    }
                })
                .then((response: AxiosResponse<{cities_index: Array<City>}>) => {
                    this.cities = response.data.cities_index
                })
        },
        openSalon(id: number) {
            this.$router.push({
                name: 'salonShow',
                params: {
                    salon_id: id
                }
            })
        }
    },
    created() {
        this.loadData()
        this.findCity('')
    }
});

export interface ISalon {
    id: number
    name: string
    address: string
    organization: Organization | null
    rating: number
    gis_id: number|null
    city: City,
    map_lat: string
    map_long: string
    contact_phone: string

    reviews_count?: number

    services?: Array<{
        id: number
        price_from: number
        time_from: number
    }>
}
