
import {defineComponent, PropType} from 'vue';
import GPopup from "@/components/Base/GPopup.vue";
import GText from "@/components/Base/GText.vue";
import BtnLoading from "@/components/BtnLoading.vue";
import {httpClient} from "@/models/HttpClient";
import {IServiceGroup} from "@/models/Interfaces";

export default defineComponent({
    name: "ServiceGroupModal",
    components: {BtnLoading, GText, GPopup},
    props: {
        propModel: {
            type: Object as PropType<IServiceGroup>
        }
    },
    data() {
        return {
            state: {
                loading: false
            },
            model: {
                name: ''
            } as IServiceGroup
        }
    },
    methods: {
        saveModel() {
            this.state.loading = true
            if (this.propModel?.id) {
                httpClient
                    .post(`/api/v1/service-groups/${this.propModel.id}`, {
                        _method: 'patch',
                        name: this.model.name
                    })
                    .then(() => {
                        this.close()
                    })
                    .finally(() => {
                        this.state.loading = false
                    })
            } else {
                httpClient
                    .post(`/api/v1/service-groups`, {
                        name: this.model.name
                    })
                    .then(() => {
                        this.close()
                    })
                    .finally(() => {
                        this.state.loading = false
                    })
            }
        },
        close() {
            this.$emit('close')
        }
    },
    created() {
        if (this.propModel?.id)
            this.model = {...this.propModel}
    }
});
