import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "nav nav-tabs mb-3" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_system_settings_index = _resolveComponent("system-settings-index")!
  const _component_service_groups_index = _resolveComponent("service-groups-index")!
  const _component_services_index = _resolveComponent("services-index")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (t) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "nav-item pointer",
          key: `tab_${t.id}`
        }, [
          _createElementVNode("a", {
            class: _normalizeClass(["nav-link", {active: t.id === _ctx.tab}]),
            onClick: ($event: any) => (_ctx.setTabId(t.id))
          }, _toDisplayString(t.name), 11, _hoisted_2)
        ]))
      }), 128))
    ]),
    (_ctx.tab === 0)
      ? (_openBlock(), _createBlock(_component_system_settings_index, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.tab === 1)
      ? (_openBlock(), _createBlock(_component_service_groups_index, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.tab === 2)
      ? (_openBlock(), _createBlock(_component_services_index, {
          key: 2,
          "pet-types": _ctx.petTypes
        }, null, 8, ["pet-types"]))
      : _createCommentVNode("", true)
  ], 64))
}