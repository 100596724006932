
import {defineComponent} from 'vue';
import GCard from "@/components/Base/GCard.vue";
import {httpClient} from "@/models/HttpClient";
import {ISalon} from "@/views/Salons/SalonsIndex.vue";
import {AxiosResponse} from "axios";
import GLoader from "@/components/Base/GLoader.vue";
import GText from "@/components/Base/GText.vue";
import {ISalonReview, IService, IServiceGroup} from "@/models/Interfaces";
import GChecks from "@/components/Base/GChecks.vue";
import BtnLoading from "@/components/BtnLoading.vue";
import store from "@/store";
import {ToastTypes} from "@/components/ToastService/ToastsService";

export default defineComponent({
    name: "SalonShow",
    components: {BtnLoading, GChecks, GText, GLoader, GCard},
    props: {
        salon_id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            state: {
                loading: false,
                servicesSaving: false
            },
            service_groups: [] as Array<IServiceGroup>,
            services: [] as Array<IService>,
            salon: { } as ISalon,
            services_checked: {} as {[key:string] : boolean},
            price_from: {} as {[key:string] : number},
            time_from: {} as {[key:string] : number},
            reviews: [] as Array<ISalonReview>
        }
    },
    methods: {
        loadData() {
            this.state.loading = true
            httpClient
                .get(`/api/v1/salons/${this.salon_id}`)
                .then((response: AxiosResponse<ISalonShow>) => {
                    this.service_groups = response.data.data.service_groups
                    this.services = response.data.data.services
                    this.salon = response.data.data.salon
                    this.reviews = response.data.data.reviews

                    this.salon.services?.forEach(s => {
                        this.services_checked[s.id] = true
                        this.price_from[s.id] = s.price_from
                        this.time_from[s.id] = s.time_from
                    })
                })
                .catch(() => {
                    store.state.toasts.addToast({
                        title: 'Ошибка!',
                        type: ToastTypes.error
                    })
                })
                .finally(() => {
                    this.state.loading = false
                })
        },
        setServices() {
            let data = {} as {
                [key:string]: {
                    price_from: number,
                    time_from: number,
                }
            }
            Object.keys(this.services_checked).forEach(key => {
                if (this.services_checked[key as keyof {[key:string] : boolean}]) {
                    data[key] = {
                        price_from: this.price_from[key] ?? 0,
                        time_from: this.time_from[key] ?? 0
                    }
                }
            })
            httpClient
                .post(`/api/v1/salons/${this.salon_id}`, {
                    _method: 'patch',
                    action: 'set-services',
                    services: data
                })
                .then(() => {
                    store.state.toasts.addToast({
                        title: 'Сохранили!',
                        type: ToastTypes.success
                    })
                })
                .catch(() => {
                    store.state.toasts.addToast({
                        title: 'Ошибка!',
                        type: ToastTypes.error
                    })
                })
        },
        setVisibility(row: ISalonReview) {
            httpClient
                .post(`/api/v1/salons/${this.salon_id}/reviews/${row.id}`, {
                    action: 'set-visibility',
                    visibility: !row.visibility
                })
                .then(() => {
                    row.visibility = !row.visibility
                    store.state.toasts.addToast({
                        title: 'Сохранили!',
                        type: ToastTypes.success
                    })
                })
        }
    },
    created() {
        this.loadData()
    }
});

export interface ISalonShow {
    data: {
        service_groups: Array<IServiceGroup>,
        services: Array<IService>
        salon: ISalon
        reviews: Array<ISalonReview>
    }
}
