import {RouteLocationRaw} from "vue-router";

export class ToastsService implements ToastsServiceI {
	id = 0 as number
	list = [] as Array<Toasts>
	
	/**
	 * data - title, type
	 * @param data
	 */
	addToast(data: Toasts) {
		this.id++;
		if (data.onceTag && this.list.find(tag => tag.onceTag === data.onceTag))
			return false;
		
		const newData = {
			id: this.id,
			timeout: data.timeout ?? 2000,
			timeId: 0,
			...data
		} as Toasts
		newData.timeId = setTimeout(() => { this.close(newData.id) }, data.timeout ?? 3000)
		this.list.push(newData)
		return true;
	}
	
	rejectAutoClose(id: number) {
		const row = this.list.find(row => row.id === id)
		if (row) clearTimeout(row.timeId)
	}
	
	startAutoClose(id: number) {
		const row = this.list.find(row => row.id === id)
		if (row) {
			clearTimeout(row.timeId) //если вдруг таймер закрытия уже есть
			row.timeId = setTimeout(() => { this.close(row.id) }, row.timeout)
		}
	}
	
	close(id: number | undefined) {
		if (id) {
			const key = this.list.findIndex(row => row.id === id)
			this.list.splice(key, 1)
		}
	}
}

export interface ToastsServiceI {
	id: number
	list: Array<Toasts>
}

export interface Toasts {
	id?: number
	timeId?: number
	title: string
	body?: string
	route?: RouteLocationRaw
	type: ToastTypes,
	timeout?: number,
	onceTag?: string //тег для отслеживания показа только 1 ошибки - делал для принудительного выхода из системы
}

export enum ToastTypes {
	primary= 'primary',
	error = 'error',
	success = 'success'
}
