export const statuses = [
	{
		value: 0,
		name: 'Обрабатывается'
	},
	{
		value: 1,
		name: 'Передана'
	},
	{
		value: 2,
		name: 'Отмена салоном'
	},
	{
		value: 3,
		name: 'Отмена'
	},
]

export const salonRequestStatuses = [
	{
		value: 0,
		name: 'draft'
	},
	{
		value: 1,
		name: 'reject'
	},
	{
		value: 2,
		name: 'await_create'
	},
	{
		value: 3,
		name: 'created'
	},
]


export const tariffTypes = [
	{
		value: 0,
		name: 'Free'
	},
	{
		value: 1,
		name: 'Demo'
	},
	{
		value: 2,
		name: 'Фикс'
	},
	{
		value: 3,
		name: 'По записям'
	},
	{
		value: 4,
		name: 'Свободный счет'
	}
]

export const subscriptionTransactionType = [
	{
		value: 0,
		name: 'Черновик',
		to_sum: false
	},
	{
		value: 1,
		name: 'Отменен',
		to_sum: false
	},
	{
		value: 2,
		name: 'Оплачено',
		to_sum: true
	},
	{
		value: 3,
		name: 'Частичный возврат',
		to_sum: false
	},
	{
		value: 4,
		name: 'Возврат',
		to_sum: false
	},
	{
		value: 5,
		name: 'Оплачено',
		to_sum: true
	},
	{
		value: 6,
		name: 'Оплачено',
		to_sum: true
	},
	{
		value: 7,
		name: 'Частичная отмена',
		to_sum: false
	},
	{
		value: 8,
		name: 'Операция отменена',
		to_sum: false
	},
]
