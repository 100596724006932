
import {defineComponent} from 'vue';
import GPaginate from "@/components/Base/GPaginate.vue";
import GCard from "@/components/Base/GCard.vue";
import {Pagination, PaginationMeta} from "@/models/Interfaces";
import {httpClient} from "@/models/HttpClient";
import {AxiosResponse} from "axios";
import {ISalon} from "@/views/Salons/SalonsIndex.vue";
import OrderModal from "@/views/Orders/OrderModal.vue";
import {statuses} from "@/defData";

export default defineComponent({
    name: "OrdersIndex",
    components: {OrderModal, GCard, GPaginate},
    data() {
        return {
            meta: {
                current_page: 1,
                per_page: 20,
            } as PaginationMeta,
            models: [] as Array<ISalonOrderRequest>,
            statuses,
            state: {
                showOrderModal: false
            },
            order: {} as ISalonOrderRequest
        }
    },
    methods: {
        setPage(page:number) {
            this.meta.current_page = page
            this.loadData()
        },
        loadData() {
            httpClient
                .get(`/api/salon-order-requests`, {
                    params: {
                        page: this.meta.current_page,
                        per_page: this.meta.per_page
                    }
                })
                .then((response: AxiosResponse<Pagination<ISalonOrderRequest>>) => {
                    this.models = response.data.models
                    this.meta = response.data.meta
                })
        },
        openOrderModal(model: ISalonOrderRequest) {
            this.order = model
            this.state.showOrderModal = true
        },
        closeOrderModal() {
            this.state.showOrderModal = false
            this.loadData()
        }
    },
    created() {
        this.loadData()
    }
});

export interface ISalonOrderRequest {
    id: number
    status: number //await_send, sended, salon_reject, client_reject
    client: {
        name: string
        phone: string
    }
    pet: {
        name: string
        gender: string
        pet_type: {
            id: number
            name: string
        }
        breed: {
            id: string
            name: string
        }
        weight: number
    }
    salon: {
        salon: ISalon
    }
    created_at: string
}
