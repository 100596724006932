import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = { class: "nav nav-tabs mb-3" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_g_loader = _resolveComponent("g-loader")!
  const _component_g_card = _resolveComponent("g-card")!
  const _component_version_create_modal = _resolveComponent("version-create-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      (_ctx.is_loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_g_loader)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_g_card, { class: "mb-3" }, {
              header: _withCtx(() => [
                _createTextVNode(" Актуальные версии ")
              ]),
              default: _withCtx(() => [
                _createTextVNode(" " + _toDisplayString(_ctx.versionsManager.actual), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("a", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openVersionCreateModal && _ctx.openVersionCreateModal(...args))),
                class: "btn btn-success"
              }, "Создать")
            ]),
            _createElementVNode("ul", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.versionsManager.version_types, (t) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "nav-item",
                  key: `tab_${t.value}`
                }, [
                  _createElementVNode("a", {
                    class: _normalizeClass(["nav-link pointer", {active: t.value === _ctx.type.value}]),
                    onClick: ($event: any) => (_ctx.type = t)
                  }, _toDisplayString(t.name), 11, _hoisted_5)
                ]))
              }), 128))
            ]),
            _createVNode(_component_g_card, null, {
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(_ctx.versionsManager.versions[_ctx.type.name]), 1)
              ]),
              _: 1
            })
          ]))
    ]),
    (_ctx.showVersionCreateModal)
      ? (_openBlock(), _createBlock(_component_version_create_modal, {
          key: 0,
          type: _ctx.type.value,
          onClose: _ctx.closeVersionCreateModal
        }, null, 8, ["type", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}