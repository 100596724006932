import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_g_text = _resolveComponent("g-text")!
  const _component_g_select = _resolveComponent("g-select")!
  const _component_btn_loading = _resolveComponent("btn-loading")!
  const _component_g_popup = _resolveComponent("g-popup")!

  return (_openBlock(), _createBlock(_component_g_popup, { title: "Услуга" }, {
    body: _withCtx(() => [
      _createVNode(_component_g_text, {
        label: "Название",
        modelValue: _ctx.model.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event)),
        required: ""
      }, null, 8, ["modelValue"]),
      _createVNode(_component_g_text, {
        label: "Описание",
        textarea: "",
        "textarea-auto-height": "",
        modelValue: _ctx.model.description,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.description) = $event)),
        required: ""
      }, null, 8, ["modelValue"]),
      _createVNode(_component_g_select, {
        label: "Тип питомца",
        modelValue: _ctx.model.pet_type_id,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.pet_type_id) = $event)),
        required: "",
        options: _ctx.petTypes.map(row => {
                    return {
                        value: row.id,
                        name: row.name
                    }
                })
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_g_select, {
        label: "Группа",
        modelValue: _ctx.model.service_group_id,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.service_group_id) = $event)),
        required: "",
        options: _ctx.serviceGroups.map(row => {
                    return {
                        value: row.id,
                        name: row.name
                    }
                })
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_btn_loading, {
        title: _ctx.propModel?.id ? 'Обновить' : 'Создать',
        state: _ctx.state.loading,
        onClick: _ctx.saveModel
      }, null, 8, ["title", "state", "onClick"])
    ]),
    _: 1
  }))
}