
import {defineComponent} from "vue";
import GLoader from "@/components/Base/GLoader.vue";

export default defineComponent({
    name: "GModal",
    components: {GLoader},
    emits: ['close', 'submit'],
    props: {
        title: {
            type: String, required: false
        },
        modalType: {
            type: String, required: false
        },
        bodyClass: {
            type: String, required: false
        },
        canCloseOutSide: {
            type: Boolean, required: false, default: true
        },
        hide: {
            default: false
        },
        isLoading: {
            default: false
        }
    },
    data() {
        return {
            show: false
        }
    },
    methods: {
        close() {
            this.show = false
            setTimeout(() => { this.$emit('close') }, 300)
        },
        closeOutSide(event: Event):void {
            if (this.canCloseOutSide && (event.target as HTMLElement).classList.contains('fade')) {
                this.close()
            }
        }
    },
    created() {
        setTimeout(() => {  this.show = true }, 50)
    }
})
