import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_g_text = _resolveComponent("g-text")!
  const _component_btn_loading = _resolveComponent("btn-loading")!
  const _component_g_popup = _resolveComponent("g-popup")!

  return (_openBlock(), _createBlock(_component_g_popup, {
    title: "Группа",
    onClose: _ctx.close
  }, {
    body: _withCtx(() => [
      _createVNode(_component_g_text, {
        label: "Название",
        modelValue: _ctx.model.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event)),
        required: ""
      }, null, 8, ["modelValue"]),
      _createVNode(_component_btn_loading, {
        title: _ctx.propModel?.id ? 'Обновить' : 'Создать',
        state: _ctx.state.loading,
        onClick: _ctx.saveModel
      }, null, 8, ["title", "state", "onClick"])
    ]),
    _: 1
  }, 8, ["onClose"]))
}