import {httpClient} from "@/models/HttpClient";
import {AxiosResponse} from "axios";
import {reactive} from "vue";

export class VersionsManager {
	
	actual = reactive({}) as version_actual
	version_types = reactive([]) as Array<version_type>
	versions = reactive({}) as versions
	
	is_loading = true
	
	loadVersions() {
		this.is_loading = true
		return httpClient
			.get(`/api/v1/versions`)
			.then((response: AxiosResponse<{version_index: version_index}>) => {
				this.actual = response.data.version_index.actual
				this.version_types = response.data.version_index.version_types
				this.versions = response.data.version_index.versions
			})
			.finally(() => {
				this.is_loading = false
			})
	}
	
	create(type: number, version: string) {
		return httpClient
			.post(`/api/v1/versions`, {
				type: type,
				version: version
			})
	}
}


export const versionsManager = new VersionsManager();


export interface version_index {
	actual: version_actual
	version_types: Array<version_type>
	versions: versions
}

export interface version_actual {
	CRM: string
	LK: string
	BACKEND: string
	NGINX: string
}

export interface version_type {
	value: number
	name: string
}

export interface versions {
	[key:string]: Array<string>
}
