import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = {
  key: 0,
  class: "card-header"
}
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = {
  key: 1,
  class: "card-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('submit')), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.$slots.header)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "header")
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "body"),
        _renderSlot(_ctx.$slots, "default")
      ]),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "footer")
          ]))
        : _createCommentVNode("", true)
    ])
  ], 32))
}