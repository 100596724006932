
import {defineComponent, PropType} from 'vue';
import GPopup from "@/components/Base/GPopup.vue";
import GText from "@/components/Base/GText.vue";
import BtnLoading from "@/components/BtnLoading.vue";
import GSelect from "@/components/Base/GSelect.vue";
import {httpClient} from "@/models/HttpClient";
import {IPetType, IService, IServiceGroup} from "@/models/Interfaces";

export default defineComponent({
    name: "ServiceModal",
    components: {GSelect, BtnLoading, GText, GPopup},
    props: {
        serviceGroups: {
            type: Object as PropType<Array<IServiceGroup>>,
            required: true
        },
        propModel: {
            type: Object as PropType<IService>
        },
        petTypes: {
            type: Object as PropType<Array<IPetType>>,
            required: true
        }
    },
    data() {
        return {
            model: {
                name: ''
            } as IService,
            state: {
                loading: false
            }
        }
    },
    methods: {
        saveModel() {
            this.state.loading = true
            if (this.propModel?.id) {
                httpClient
                    .post(`/api/v1/services/${this.propModel.id}`, {
                        _method: 'patch',
                        ...this.model
                    })
                    .then(() => {
                        this.close()
                    })
                    .finally(() => {
                        this.state.loading = false
                    })
            } else {
                httpClient
                    .post(`/api/v1/services`, this.model)
                    .then(() => {
                        this.close()
                    })
                    .finally(() => {
                        this.state.loading = false
                    })
            }
        },
        close() {
            this.$emit('close')
        }
    },
    created() {
        if (this.propModel?.id)
            this.model = {...this.propModel}
    }
});
