import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_g_loader = _resolveComponent("g-loader")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([_ctx.$props.class, "d-flex align-items-center justify-content-center"]),
    disabled: _ctx.state
  }, [
    (_ctx.state)
      ? (_openBlock(), _createBlock(_component_g_loader, {
          key: 0,
          color: `var(${_ctx.loaderColor})`,
          height: _ctx.loaderHeight,
          width: _ctx.loaderWidth,
          class: "position-absolute"
        }, null, 8, ["color", "height", "width"]))
      : _createCommentVNode("", true),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass({'opacity-0': _ctx.state})
        }, _toDisplayString(_ctx.title), 3))
      : (!_ctx.state)
        ? _renderSlot(_ctx.$slots, "default", { key: 2 })
        : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}