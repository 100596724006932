
import {defineComponent} from 'vue';
import {ITicket, TicketsManager} from "@/models/TicketsManager";
import {Pagination} from "@/models/Interfaces";
import GCard from "@/components/Base/GCard.vue";
import router from "@/router";
import GPaginate from "@/components/Base/GPaginate.vue";
import GLoader from "@/components/Base/GLoader.vue";

export default defineComponent({
    name: "TicketsIndex",
    components: {GLoader, GPaginate, GCard},
    props: {
        organization_id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            tickets: {
                meta: {
                    current_page: 1,
                    per_page: 15,
                    count: 0,
                    total: 0,
                    total_pages: 0,
                },
                models: [] as Array<ITicket>
            } as Pagination<ITicket>,
            manager: new TicketsManager(this.organization_id),
            is_loading: false
        }
    },
    methods: {
        loadData() {
            this.is_loading = true
            this.manager
                .index()
                .then(response => {
                    this.tickets.models = response.data.tickets_index.tickets.models
                })
                .finally(() => {
                    this.is_loading = false
                })
        },
        openTicket(ticket_id: number) {
            router.push({
                name: 'organization.ticket.show',
                params: {
                    ticket_id: ticket_id
                }
            })
        },
        setPage(page: number) {
            console.log(page)
        }
    },
    created() {
        this.loadData()
    }
});
