
import {defineComponent, PropType} from 'vue';
import GPopup from "@/components/Base/GPopup.vue";
import {ISalonRequest} from "@/views/Salons/Requests/SalonRequestsIndex.vue";
import GText from "@/components/Base/GText.vue";
import GSelect from "@/components/Base/GSelect.vue";
import {City} from "@/models/Interfaces";
import {httpClient} from "@/models/HttpClient";
import {AxiosResponse} from "axios";
import BtnLoading from "@/components/BtnLoading.vue";
import {salonRequestStatuses} from "@/defData";

export default defineComponent({
    name: "SalonRequestsUpdate",
    components: {BtnLoading, GSelect, GText, GPopup},
    props: {
        salonRequest: {
            type: Object as PropType<ISalonRequest>,
            required: true
        }
    },
    data() {
        return {
            model: {...this.salonRequest},
            state: {
                is_city_loading: false,
                saving: false
            },
            cities: [] as Array<City>,
        }
    },
    methods: {
        salonRequestStatuses() {
            return salonRequestStatuses
        },
        findCity(city_name: string) {
            httpClient
                .get(`/api/app/v1/cities`, {
                    params: {
                        search_name: city_name,
                        city_id: this.model.city_id
                    }
                })
                .then((response: AxiosResponse<{cities_index: Array<City>}>) => {
                    this.cities = response.data.cities_index
                })
        },
        save() {
            this.state.saving = true
            httpClient
                .post(`/api/salon-requests/${this.model.id}`, this.model)
                .then(() => {
                    this.$emit('close')
                })
                .finally(() => {
                    this.state.saving = false
                })
        }
    },
    created() {
        this.findCity('')
    }
});
