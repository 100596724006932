
import {defineComponent} from "vue";
import GCard from "@/components/Base/GCard.vue";
import {httpClient} from "@/models/HttpClient";
import {Organization, Pagination, PaginationMeta} from "@/models/Interfaces";
import {AxiosResponse} from "axios";
import GPaginate from "@/components/Base/GPaginate.vue";
import GLoader from "@/components/Base/GLoader.vue";
import {tariffTypes} from "@/defData";
import GChecks from "@/components/Base/GChecks.vue";

export default defineComponent({
    components: {GChecks, GLoader, GPaginate, GCard},
    created() {
        this.loadOrganizations()
    },
    data() {
        return {
            models: [] as Array<Organization>,
            meta: {
                current_page: 1,
                per_page: 10
            } as PaginationMeta,
            statuses: {
                draft: 'Новая',
                work: 'old',
                k8s: 'Активен',
                pause: 'Пауза'
            },
            only_in_work: true,
            only_minus_balance: false,
            only_expired_demo: false,
            is_loading: false,
            tariffTypes
        }
    },
    methods: {
        setPage(page: number) {
            this.meta.current_page = page
            this.loadOrganizations()
        },
        setFirstPage() {
            this.meta.current_page = 1
            this.loadOrganizations()
        },
        loadOrganizations() {
            this.is_loading = true
            httpClient
                .get(`/api/organizations`, {
                    params: {
                        page: this.meta.current_page,
                        per_page: this.meta.per_page,
                        only_in_work: this.only_in_work ? 1 : 0,
                        only_minus_balance: this.only_minus_balance ? 1 : 0,
                        only_expired_demo: this.only_expired_demo ? 1 : 0
                    }
                })
                .then((response: AxiosResponse<Pagination<Organization>>) => {
                    this.models = response.data.models
                    this.meta = response.data.meta
                })
                .finally(() => {
                    this.is_loading = false
                })
        }
    }
})
