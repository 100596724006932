
import {defineComponent} from "vue";
import GCard from "@/components/Base/GCard.vue";
import {UserModel} from "@/models/UserModel";
import store from "@/store";
import router from "@/router";
import {httpClient, LaravelErrorResponse} from "@/models/HttpClient";
import GText from "@/components/Base/GText.vue";
import {AxiosError} from "axios";

export default defineComponent({
    components: {GText, GCard},
    data() {
        return {
            email: '',
            password: '',
            otp: '',
            message: ''
        }
    },
    computed: {
        user() : UserModel {
            return store.state.user
        }
    },
    methods: {
        signIn() {
            this.user.auth(this.email, this.password, this.otp.replace(' ',''))
                .then(response => {
                    let token = `Bearer ${response.data.auth_data.bearer_token}`
                    localStorage.setItem('token', token)
                    httpClient.defaults.headers.common.Authorization = token

                    router.push({
                        name: 'dashboard'
                    })
                })
                .catch((error: AxiosError<LaravelErrorResponse>) => {
                    this.message = error?.response?.data.message ?? ''
                })
        }
    }
})
