
import {defineComponent} from 'vue';
import GLoader from "@/components/Base/GLoader.vue";
import DropDownMenu from "@/components/DropDownMenu/DropDownMenu.vue";
import {ITicket, ITicketMessage, TicketsManager} from "@/models/TicketsManager";

export default defineComponent({
    name: "TicketShow",
    components: {DropDownMenu, GLoader},
    props: {
        organization_id: {
            type: String,
            required: true
        },
        ticket_id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isSending: false,
            message: '',
            manager: new TicketsManager(this.organization_id),
            messages: [] as Array<ITicketMessage>,
            interval: 0,
            ticket: {} as ITicket,
        }
    },
    methods: {
        loadData() {
            this.manager
                .show()
                .then(response => {
                    this.messages = response.data.ticket_messages_index.messages
                    this.ticket = response.data.ticket_messages_index.ticket
                })
        },
        sendTextMessage() {
            this.manager
                .sendTextMessage(this.message)
                .then((response) => {
                    this.messages.unshift(response.data.ticket_message_resource)
                    this.message = ''
                })
                .finally(() => {
                    this.isSending = false
                })
        },
        openPhotoSelect() {
            (this.$refs.photo as HTMLInputElement).click()
        },
        openDocumentSelect() {
            (this.$refs.document as HTMLInputElement).click()
        },
        sendMessageFile(type: string) {
            let files = this.$refs[type] as HTMLInputElement
            if (files.files && files.files[0]) {
                this.isSending = true
                this.manager
                    .sendFormDataMessage(files.files[0], type)
                    .then((response) => {
                        this.messages.unshift(response.data.ticket_message_resource)
                        this.message = ''
                    })
                    .finally(() => {
                        this.isSending = false
                    })
                files.value = ""
            }
        },
        closeTicket() {
            this.manager
                .closeTicket()
                .then(() => {
                    this.loadData()
                })
        },
        openTicket() {
            this.manager
                .openTicket()
                .then(() => {
                    this.loadData()
                })
        }
    },
    created() {
        this.manager.setTicketId(this.ticket_id)
        this.loadData()
        // this.interval = setInterval(() => {
        //     this.loadData()
        // }, 10000)
    },
    unmounted() {
        clearInterval(this.interval)
    }
});
