
import {defineComponent, PropType} from 'vue';
import GPopup from "@/components/Base/GPopup.vue";
import GFilePicker from "@/components/Base/GFilePicker.vue";
import {GFile, SiteTemplate} from "@/models/Interfaces";
import GText from "@/components/Base/GText.vue";
import {httpClient} from "@/models/HttpClient";

export default defineComponent({
    name: "CreateTemplateVersionPopup",
    components: {GText, GFilePicker, GPopup},
    props: {
        template: {
            type: Object as PropType<SiteTemplate>,
            required: true
        }
    },
    data() {
        return {
            file: {} as GFile,
            version: '0.0.1',
            description: '',
        }
    },
    methods: {
        submit() {
            let data = new FormData()
            data.append('version', this.version)
            data.append('description', this.description)
            if (this.file.new_file)
                data.append('template', this.file.new_file)

            httpClient.post(`/api/site-templates/${this.template.id}/site-template-versions`, data)
                .then(() => {
                    this.close()
                })
        },
        close() {
            this.$emit('close')
        }
    }
});
