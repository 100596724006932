import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_g_text = _resolveComponent("g-text")!
  const _component_btn_loading = _resolveComponent("btn-loading")!
  const _component_g_modal = _resolveComponent("g-modal")!

  return (_openBlock(), _createBlock(_component_g_modal, {
    title: "Сформировать счет",
    onClose: _ctx.close,
    onSubmit: _ctx.create
  }, {
    body: _withCtx(() => [
      _createVNode(_component_g_text, {
        label: "Кол-во месяцев",
        number: "",
        min: "1",
        max: "36",
        placeholder: "от 1 до 36 мес",
        modelValue: _ctx.transaction.month,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.transaction.month) = $event)),
        formText: `${_ctx.monthPrice * _ctx.transaction.month} руб`
      }, null, 8, ["modelValue", "formText"]),
      _createVNode(_component_btn_loading, {
        title: "Сформировать",
        state: _ctx.state.creating
      }, null, 8, ["state"])
    ]),
    _: 1
  }, 8, ["onClose", "onSubmit"]))
}