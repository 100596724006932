
import {defineComponent} from 'vue';
import GPopup from "@/components/Base/GPopup.vue";
import GText from "@/components/Base/GText.vue";
import {versionsManager} from "@/models/VersionsManager";

export default defineComponent({
    name: "VersionCreateModal",
    components: {GText, GPopup},
    props: {
        type: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            version: 'v',
            versionsManager
        }
    },
    methods: {
        create() {
            versionsManager
                .create(this.type, this.version)
                .finally(() => {
                    this.close()
                })
        },
        close() {
            this.$emit('close')
        }
    }
});
