
import {defineComponent} from "vue";


export default defineComponent({
    props: {
        height: {
            type: String,
            default: "120px",
        },
        width: {
            type: String,
            default: "40px",
        },
        color: {
            type: String,
            default: "#1F3066",
        }
    },
    computed: {
        cssProps() {
            return {
                '--gloader-circle': this.color,
                'height': `${this.height}`,
                'width': `${this.width}`,
            }
        }
    }
})
