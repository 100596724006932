
import {defineComponent} from 'vue';
import GCard from "@/components/Base/GCard.vue";
import GLoader from "@/components/Base/GLoader.vue";
import {httpClient} from "@/models/HttpClient";
import {AxiosResponse} from "axios";
import {IServiceGroup} from "@/models/Interfaces";
import ServiceGroupModal from "@/views/Settings/Modals/ServiceGroupModal.vue";

export default defineComponent({
    name: "ServiceGroupsIndex",
    components: {ServiceGroupModal, GLoader, GCard},
    data() {
        return {
            state: {
                loadingServiceGroups: false,
                showServiceGroupModal: false,
            },
            serviceGroups: [] as Array<IServiceGroup>,
            serviceGroup: {} as IServiceGroup
        }
    },
    methods: {
        openServiceGroupModal(model?: IServiceGroup) {
            if (model)
                this.serviceGroup = model

            this.state.showServiceGroupModal = true
        },
        closeServiceGroupModal() {
            this.state.showServiceGroupModal = false
            this.loadServiceGroups()
        },
        loadServiceGroups() {
            this.state.loadingServiceGroups = true
            httpClient
                .get(`/api/v1/service-groups`)
                .then((response: AxiosResponse<{data: Array<IServiceGroup>}>) => {
                    this.serviceGroups = response.data.data
                })
                .catch((e) => {
                    console.warn(e)
                })
                .finally(() => {
                    this.state.loadingServiceGroups = false
                })
        },
    },
    created() {
        this.loadServiceGroups()
    }
});
