
import {defineComponent, PropType} from 'vue';
import GPopup from "@/components/Base/GPopup.vue";
import {ISalonOrderRequest} from "@/views/Orders/OrdersIndex.vue";
import GText from "@/components/Base/GText.vue";
import GSelect from "@/components/Base/GSelect.vue";
import {statuses} from "@/defData";
import {httpClient, LaravelErrorResponse} from "@/models/HttpClient";
import {AxiosError} from "axios";

export default defineComponent({
    name: "OrderModal",
    components: {GSelect, GText, GPopup},
    props: {
        order: {
            type: Object as PropType<ISalonOrderRequest>,
            required: true
        }
    },
    data() {
        return {
            statuses,
            model: {...this.order} as ISalonOrderRequest
        }
    },
    methods: {
        saveData() {
            httpClient
                .post(`/api/salon-order-requests/${this.model.id}`, {
                    _method: 'patch',
                    status: this.model.status
                })
                .then(() => {
                    this.$emit('close')
                })
                .catch((error: AxiosError<LaravelErrorResponse>) => {
                    let msg = error?.response?.data.message

                    alert(msg ?? 'Ошибка')
                })
        }
    }
});
